import Image, { ImageProps } from 'next/image';
import { CSSProperties, FC, useCallback, useState } from 'react';
import { css } from '~src/styled-system/css';
import { styled } from '~src/styled-system/jsx';
import { forwardClassName } from '~src/utils/panda/forwardClassName';

export const MdxImg = styled('img', {
  base: {
    display: 'block',
    borderRadius: 'box',
    boxShadow: 'default',
    objectFit: 'cover',
  },
});

export const SimpleImg = styled('img', {
  base: {
    display: 'block',
    objectFit: 'cover',
  },
});

// HACK: any Img cuz of weird error
export const Img = forwardClassName(Image)(
  css({
    display: 'block',
    objectFit: 'cover',
  }),
);

export const ImgContainer = styled('div', {
  base: {
    position: 'relative',
    borderRadius: 'box',
    overflow: 'hidden',
    boxShadow: 'default',
  },
});

export const InnerImgContainer = styled('div', {
  base: {
    position: 'relative',
    borderRadius: 'innerBox',
    overflow: 'hidden',
    boxShadow: 'default',
  },
});

export const SignatureImgContainer = styled('div', {
  base: {
    my: 'zero',
    mx: 'largestBox',
    borderRadius: 'zero',
    position: 'relative',
    display: 'block',
    aspectRatio: '1:1',
    width: '[42px]',
  },
});

export const BreathingImgCover = styled('div', {
  base: css.raw({
    animation: 'breath',
    abs: 'cover',
    background: 'darken',
  }),
});

const HackImg = Img as any;

export const BaseLoadingImg: FC<ImageProps & {}> = ({
  style,
  onLoad,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const handleLoadingComplete: (img: HTMLImageElement) => void = useCallback(
    (img: HTMLImageElement) => {
      setIsLoading(false);
      if (!!onLoad) {
        console.warn('onLoad is not implemented');
      }
      // props?.onLoad?.(img);
    },
    [onLoad],
  );

  return (
    <>
      <HackImg {...props} onLoad={handleLoadingComplete} />
      {isLoading && <BreathingImgCover />}
    </>
  );
};

export const LoadingImg: FC<
  ImageProps & {
    containerStyles?: CSSProperties;
  }
> = ({ containerStyles, ...props }) => {
  return (
    <ImgContainer style={{ ...containerStyles }}>
      <BaseLoadingImg {...props} />
    </ImgContainer>
  );
};

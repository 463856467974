import type { BlockverseProjectId } from '@pob/blockverse';
import { deserializeId, serializeId } from '~src/utils/strings/serializeId';
import type { BlockverseObjId } from '../types';

export const serializeBlockverseObjId = <E extends BlockverseProjectId>(
  objId: BlockverseObjId<E>,
) => {
  return serializeId(objId);
};

export const deserializeBlockverseObjId = <
  E extends BlockverseProjectId,
  R extends any[] = [...BlockverseObjId<E>],
>(
  serializedObjId: string,
) => {
  return deserializeId<R>(serializedObjId, (id) => {
    id[1] = Number(id[1]);
    return id;
  });
};

import {
  CHAIN_NETWORK_TO_CHAIN_ID_LOOKUP,
  type AvailableChainNetwork,
} from '@protocol/chains';
import { ProjectId } from '@protocol/deployments';

import {
  base,
  baseSepolia,
  lukso,
  mainnet,
  optimism,
  optimismSepolia,
} from 'viem/chains';
import { z } from 'zod';

export type BlockverseConfig = {};

export const BLOCKVERSE_CONFIG = {
  hash: {},
  exeo: {},
  hiraeth: {},
} as const satisfies {
  [project in ProjectId]?: BlockverseConfig;
};

export type BlockverseProjectId = keyof typeof BLOCKVERSE_CONFIG;

export const BLOCKVERSE_PROJECTS = Object.keys(
  BLOCKVERSE_CONFIG,
) as BlockverseProjectId[];

// // sanity checks that the BLOCKVERSE_PROJECTS array contains all the project ids
// expectType<
//   IsEqual<
//     ArrayValues<typeof BLOCKVERSE_PROJECTS>,
//     keyof typeof BLOCKVERSE_CONFIG
//   >
// >(true);

export const supportedBlockverseChainZod = z.object({
  hash: z.literal(mainnet.id),
  exeo: z.never(),
  hiraeth: z.union([
    z.literal(mainnet.id),
    z.literal(base.id),
    z.literal(baseSepolia.id),
    z.literal(lukso.id),
    z.literal(optimism.id),
    z.literal(optimismSepolia.id),
  ]),
} as const);

export type SupportedBlockverseChainId<TProjectId extends BlockverseProjectId> =
  z.infer<(typeof supportedBlockverseChainZod.shape)[TProjectId]>;

export const PROJECT_SUPPORTED_BLOCKVERSE_CHAINS = Object.fromEntries(
  Object.entries(supportedBlockverseChainZod.shape).map(([key, value]) => [
    key,
    value instanceof z.ZodNever
      ? null
      : value instanceof z.ZodLiteral
        ? {
            [value._def.value]: value._def.value,
          }
        : value._def.options
            .map((opt: z.ZodLiteral<number>) => opt._def.value)
            .reduce(
              (acc, curr) => {
                acc[curr] = curr;
                return acc;
              },
              {} as Record<number, number>,
            ),
  ]),
) as unknown as {
  [TProjectId in BlockverseProjectId]: SupportedBlockverseChainId<TProjectId> extends never
    ? null
    : {
        [K in SupportedBlockverseChainId<TProjectId>]: K;
      };
};

export const supportedBlockverseChainByNetworkZodSafeParse = <
  TProjectId extends BlockverseProjectId,
>(
  projectId: TProjectId,
  chainNetwork: AvailableChainNetwork,
) => {
  const chainId = CHAIN_NETWORK_TO_CHAIN_ID_LOOKUP[chainNetwork];
  return supportedBlockverseChainZod.shape[projectId].safeParse(chainId);
};

// // sanity checks that the zod schema for hiraeth is correct
// type ZodToIntoUnion<T> = T extends z.ZodNever
//   ? never
//   : T extends z.ZodLiteral<infer U>
//     ? T
//     : T extends z.ZodUnion<infer U>
//       ? ArrayValues<U>
//       : never;

// type UnionToZodLiteralUnion<T> = ValueOf<{
//   [K in keyof T]: z.ZodLiteral<K>;
// }>;

// expectType<
//   IsEqual<
//     {
//       [P in keyof typeof supportedBlockverseChainZod.shape]: ZodToIntoUnion<
//         (typeof supportedBlockverseChainZod.shape)[P]
//       >;
//     },
//     {
//       [P in keyof typeof PROJECT_SUPPORTED_BLOCKVERSE_CHAINS]: UnionToZodLiteralUnion<
//         (typeof PROJECT_SUPPORTED_BLOCKVERSE_CHAINS)[P]
//       >;
//     }
//   >
// >(true);

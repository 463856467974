import {
  Tooltip as ArkTooltip,
  TooltipRootProps as ArkTooltipRootProps,
} from '@ark-ui/react';
import { css } from '~src/styled-system/css';
import { styled } from '~src/styled-system/jsx';
import { forwardClassName } from '~src/utils/panda/forwardClassName';
import {
  dropdownItemGroupCss,
  dropdownItemGroupGridCss,
  dropdownLineSeparatorCss,
} from '../dropdown';
import { popoverContentCss, popoverPositionerCss } from '../popover';

export type TooltipRootProps = ArkTooltipRootProps;

export const TooltipBox = styled('div', {
  base: css.raw(dropdownItemGroupCss, {
    padding: 'ten',
    borderRadius: 'innerDropdown',
  }),
});

export const TooltipLineSeparator = styled('div', {
  base: css.raw(dropdownLineSeparatorCss, {
    mx: '[calc(-1 * {spacing.ten})]',
    my: 'ten',
  }),
});

export const TooltipBoxGrid = styled('div', {
  base: dropdownItemGroupGridCss,
});

export const TooltipP = styled('p', {
  base: {
    color: 'gray.600',
    textAlign: 'center',
    textStyle: 'p.display.caption',
    lineHeight: '[1.2]',
  },
});

export const TooltipTitleP = styled('p', {
  base: {
    color: 'gray.600',
    textAlign: 'center',
    textStyle: 'p.display',
    lineHeight: '[1.2]',
  },
});

export const tooltipTimingProps = {
  default: {
    openDelay: 100,
    closeDelay: 300,
  },
  slowOpen: {
    openDelay: 300,
    closeDelay: 300,
  },
  slowCloseAndOpen: {
    openDelay: 300,
    closeDelay: 1000,
  },
} as const;

/**
 * Tooltip component
 *  - Root
 *    - Trigger
 *    - Positioner
 *      - Content
 *        - BoxGrid
 *        - Box
 *          - Title
 *          - P
 */
export const Tooltip = {
  ...ArkTooltip,
  Positioner: forwardClassName(ArkTooltip.Positioner)(
    css(popoverPositionerCss),
  ),
  Content: forwardClassName(ArkTooltip.Content)(
    css(popoverContentCss, {
      rounded: 'innerDropdown',
    }),
  ),
  Box: TooltipBox,
  LineSeparator: TooltipLineSeparator,
  BoxGrid: TooltipBoxGrid,
  P: TooltipP,
  Title: TooltipTitleP,
};

export type TooltipPositioning = NonNullable<
  ArkTooltipRootProps['positioning']
>;

import { css } from '~src/styled-system/css';
import { styled } from '~src/styled-system/jsx';
import { baseButtonCss } from '../button';

export const dropdownItemGroupCss = css.raw({
  padding: 'halfIncrement',
  borderRadius: 'innerBox',
  background: 'white',
});

export const dropdownItemGroupGridCss = css.raw({
  display: 'grid',
  gridGap: 'halfThinGap',
});

export const deepDropdownItemGroupCss = css.raw(dropdownItemGroupCss, {
  display: 'grid',
  padding: '[calc({spacing.halfIncrement} + {spacing.ten})]',
  gridGap: '[calc({spacing.halfIncrement} + {spacing.ten})]',
});

export const dropdownLabelCss = css.raw({
  fontSize: 'legacyLabel',
  fontWeight: 'display.bold',
  color: 'gray.400',
  textAlign: 'left',
  py: 'ten',
  px: '[calc({spacing.halfIncrement} + {spacing.ten})]',
  display: 'block',
  borderBottom: '1px solid',
  borderColor: 'darken.05',
  marginBottom: 'halfIncrement',
  marginLeft: '[calc(-1 * {spacing.halfIncrement})]',
  marginRight: '[calc(-1 * {spacing.halfIncrement})]',
});

export const dropdownBottomLabelCss = css.raw(dropdownLabelCss, {
  borderBottom: 'none',
  borderTop: '1px solid',
  borderColor: 'darken.05',
  marginTop: 'halfIncrement',
  marginBottom: '[calc(-1 * {spacing.halfIncrement})]',
});

export const dropdownItemCss = css.raw(baseButtonCss, {
  display: 'block',
  fontFamily: 'display',
  paddingX: 'ten',
  paddingY: 'ten',
  rounded: 'innerDropdown',
  color: 'gray.600',
  textAlign: 'left',
  tran: 'default',
  fontWeight: 'display.normal',
  fontSize: 'legacyLabel',
  transitionProperty: ['color', 'background'],
  textDecoration: 'none',
  _disabled: {
    opacity: '50',
    cursor: 'not-allowed',
  },
  _hover: {
    color: 'black',
    background: 'darken.05',
  },
  // _focusVisible: {
  //   color: 'black',
  //   background: 'darken.05',
  // },
});

export const compactDropdownItemCss = css.raw(dropdownItemCss, {
  paddingY: 'threeQuartersIncrement',
});

export const dropdownLineSeparatorCss = css.raw({
  height: 'nudge',
  bg: 'darken.05',
  // width: '100%',
  mx: '[calc(-1 * {spacing.halfIncrement})]',
  my: 'halfIncrement',
});

export const DropdownLineSeparator = styled('div', {
  base: dropdownLineSeparatorCss,
});

export const DropdownItemGroup = styled('div', {
  base: dropdownItemGroupCss,
});

export const DeepDropdownItemGroup = styled('div', {
  base: deepDropdownItemGroupCss,
});

export const DropdownItemGroupGrid = styled('div', {
  base: dropdownItemGroupGridCss,
});

export const DropdownLabel = styled('label', {
  base: dropdownLabelCss,
});

export const DropdownBottomLabel = styled('label', {
  base: dropdownBottomLabelCss,
});

export const DropdownItemA = styled('a', {
  base: dropdownItemCss,
});

export const DropdownItemButton = styled('button', {
  base: css.raw(dropdownItemCss, {
    width: '100%',
  }),
});

export const CompactDropdownItemButton = styled('button', {
  base: css.raw(compactDropdownItemCss, {
    width: '100%',
  }),
});

export const CompactDropdownItemA = styled('a', {
  base: compactDropdownItemCss,
});

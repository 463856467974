import type { RouteLiteral, Route as TypedRoute } from '~src/types/routes';

interface Route {
  pathname: string;
  query?: { [key: string]: string | string[] | undefined };
  hash?: string | null | undefined;
}

interface OptionalCatchAll extends Route {
  query?: { [key: string]: string[] | undefined };
}

interface CatchAll extends Route {
  query: { [key: string]: string[] };
}

interface Dynamic extends Route {
  query: { [key: string]: string };
}

interface RouteOptions {
  trailingSlash?: boolean;
}

export function route(
  r: TypedRoute,
  options: RouteOptions = { trailingSlash: false },
): RouteLiteral {
  const params = new Set<string>();
  let path =
    '/' +
    r.pathname
      .split('/')
      .map((segment) => {
        // optional catch all
        if (segment.startsWith('[[...') && segment.endsWith(']]')) {
          const query = segment.slice(5, -2);
          params.add(query);
          return (r as OptionalCatchAll).query?.[query]?.join('/');
        }
        // catch all
        if (segment.startsWith('[...') && segment.endsWith(']')) {
          const query = segment.slice(4, -1);
          params.add(query);
          return (r as CatchAll).query[query].join('/');
        }
        // dynamic
        if (segment.startsWith('[') && segment.endsWith(']')) {
          const query = segment.slice(1, -1);
          params.add(query);
          return (r as Dynamic).query[query];
        }
        return segment;
      })
      // removes optional catch all if no query is supplied
      .filter(Boolean)
      .join('/');

  if (options.trailingSlash) {
    path += '/';
  }

  const search = new URLSearchParams();
  for (const key in r.query) {
    if (!params.has(key)) {
      const value = r.query[key];
      if (Array.isArray(value)) {
        value.forEach((val) => search.append(key, val));
      } else if (value !== undefined) {
        search.append(key, value);
      }
    }
  }
  const qs = search.toString().length > 0 ? '?' + search.toString() : '';
  const hash = r.hash ? '#' + r.hash : '';

  return (path + qs + hash) as RouteLiteral;
}

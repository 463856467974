import { FC } from 'react';

export const SearchIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 4C7.13401 4 4 7.13401 4 11C4 14.866 7.13401 18 11 18C12.886 18 14.5978 17.2541 15.8565 16.0413C15.8828 16.0071 15.9116 15.9742 15.9429 15.9429C15.9742 15.9116 16.0071 15.8827 16.0413 15.8564C17.2542 14.5977 18 12.886 18 11C18 7.13401 14.866 4 11 4ZM18.0319 16.6177C19.2635 15.078 20 13.125 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C13.125 20 15.078 19.2635 16.6177 18.0319L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0977 21.3166 22.0977 20.6834 21.7071 20.2929L18.0319 16.6177Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const GlobeSearchIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      height="18"
      width="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor">
        <path
          d="M9.714,15.461c-.234,.025-.473,.039-.714,.039-.589,0-1.348-1.05-1.817-2.849,.601,.063,1.211,.099,1.817,.099,.244,0,.485-.004,.723-.014,.414-.017,.736-.366,.719-.78-.016-.415-.418-.74-.779-.719-.218,.009-.439,.013-.662,.013-.761,0-1.467-.05-2.115-.135-.086-.647-.135-1.354-.135-2.115s.05-1.467,.135-2.115c.647-.085,1.354-.135,2.115-.135s1.467,.05,2.115,.135c.086,.647,.135,1.354,.135,2.115,0,.226-.005,.45-.013,.67-.017,.414,.307,.762,.721,.778,.409,.017,.762-.307,.777-.721,.01-.24,.015-.482,.015-.728,0-.606-.036-1.216-.099-1.817,1.8,.469,2.849,1.228,2.849,1.817,0,.241-.013,.48-.039,.714-.044,.412,.254,.782,.665,.827,.401,.043,.782-.252,.827-.665,.03-.288,.047-.581,.047-.876,0-4.411-3.589-8-8-8S1,4.589,1,9s3.589,8,8,8c.296,0,.589-.016,.876-.047,.412-.045,.709-.415,.665-.827-.045-.412-.421-.708-.827-.665Zm-4.365-4.644c-1.8-.469-2.849-1.228-2.849-1.817s1.049-1.349,2.849-1.817c-.063,.601-.099,1.211-.099,1.817s.036,1.216,.099,1.817Zm3.651-5.567c-.606,0-1.216,.036-1.817,.099,.469-1.8,1.228-2.849,1.817-2.849s1.348,1.05,1.817,2.849c-.601-.063-1.211-.099-1.817-.099Zm5.98,1.205c-.732-.372-1.618-.658-2.576-.859-.201-.958-.487-1.844-.859-2.575,1.54,.658,2.776,1.894,3.434,3.434ZM6.455,3.02c-.372,.732-.658,1.618-.859,2.575-.958,.201-1.844,.487-2.576,.859,.658-1.54,1.894-2.776,3.434-3.434ZM3.02,11.545c.732,.372,1.618,.658,2.575,.859,.201,.958,.487,1.844,.859,2.575-1.54-.658-2.776-1.894-3.434-3.434Z"
          fill="currentColor"
        />
        <path d="M16.575,15.514c.263-.446,.425-.96,.425-1.514,0-1.654-1.346-3-3-3s-3,1.346-3,3,1.346,3,3,3c.555,0,1.068-.162,1.514-.425l1.205,1.205c.146,.146,.338,.22,.53,.22s.384-.073,.53-.22c.293-.293,.293-.768,0-1.061l-1.205-1.205Zm-4.075-1.514c0-.827,.673-1.5,1.5-1.5s1.5,.673,1.5,1.5c0,.413-.168,.787-.438,1.058,0,0-.002,0-.002,.002s0,.002-.002,.002c-.271,.271-.645,.438-1.058,.438-.827,0-1.5-.673-1.5-1.5Z" />
      </g>
    </svg>
  );
};

import { btoa } from 'isomorphic-base64';

export const getBase64PlaceholderImage = (
  width: number,
  height: number,
  fill?: string,
) => {
  return `data:image/svg+xml;base64,${btoa(`
  <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" style="background:${fill ?? '#EFEFEF'}"></svg>
  `)}`;
};

import { usePrivy } from '@privy-io/react-auth';
import { trpc } from '~src/clients/trpc-client';
import { CONSTANT_QUERY_LIVENESS } from '~src/constants/query';

export const useUniversalUserFromPrivy = () => {
  const { user } = usePrivy();
  return useUniversalUser(user?.id).data;
};

const useUniversalUser = (did: string | undefined) => {
  return trpc.user.universal.byDid.useQuery(
    { did: did! },
    {
      enabled: !!did,
      ...CONSTANT_QUERY_LIVENESS,
    },
  );
};
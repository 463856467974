import { mainnet } from 'viem/chains';
import { z } from 'zod';
import type { BitcoinProjectId } from './bitcoin/types';
import { evmDeployments } from './evm';
import type { EvmNftDeployment, EvmProjectId } from './evm/types';

export * from './bitcoin/types';
export * from './evm/types';

export interface IpfsProjectDeployment extends EvmNftDeployment {
  contractURI: string;
  metadataURI: string;
}

export interface AdditionalGithubMetadata {
  repoUrl: string;
}

export interface AdditionalRenderingMetadata {
  shouldHide: boolean;
}

export type ProjectId = EvmProjectId | BitcoinProjectId;

export const projectIdZod = z.custom<ProjectId>((value) => {
  if (typeof value !== 'string') {
    return false;
  }
  const isEvmProjectId = !Object.keys(
    evmDeployments[mainnet.id].projects,
  ).includes(value);

    return isEvmProjectId;
  },
  'Invalid project id',
);

import { NULL_ADDRESS } from '@pob/shared';
import { StrippedEvmDeployment } from '../types';
export const sepoliaEvmDeployment = {
  pob: {
    multisig: NULL_ADDRESS,
    legacyMultisig: NULL_ADDRESS,
    deployer: '0x0B7056e2D9064f2ec8647F1ae556BAcc06da6Db4',
    team: '0x9428ca8b5fe52C33BD0BD7222d719d788B6467F4',
    universalProfile: NULL_ADDRESS,
  },
  account: {
    entryPoint: NULL_ADDRESS,
  },
  libraries: {},
  components: {},
  utils: {
    mintingMultiCall: '0x4a82Dccd69C7Cf1C427dbA62E145937bAfD91f19',
    multiCall: '0xcA11bde05977b3631167028862bE2a173976CA11',
    scriptyBuilder: NULL_ADDRESS,
    onchainMetadata: NULL_ADDRESS,
    unicode: NULL_ADDRESS,
  },
  scripty: {
    storage: NULL_ADDRESS,
    builder: NULL_ADDRESS,
    ethfsStorage: NULL_ADDRESS,
  },
  projects: {
    hiraeth: undefined,
    dead: undefined,
    evenOdd: undefined,
    exampleWithIpfs: undefined,
    example: undefined,
    exeo: undefined,
    hash: {
      repoUrl: 'https://github.com/proofofbeauty/HASH-monorepo',
      nft: '0xa7fBfFBD9b3f1074C7e1eb1DEb1B5C9FC6FD5B2a',
      legacyNft: '0x21913973ee494F056A87D26CD4fD127180a7f832',
      minter: '0x6A2FfbcdB205885d633464A6a2C3f6ae14313EC2',
      registry: '0xd390CbD3208d375ae36E3596fAdDf2f2343D41C6',
      legacyRegistry: NULL_ADDRESS,
      deprecatedNft: NULL_ADDRESS,
    },
    merge: undefined,
    piano: undefined,
    london: undefined,
  },
} as const satisfies StrippedEvmDeployment;

import { NULL_ADDRESS } from '@pob/shared';
import type { StrippedEvmDeployment } from '../types';

export const baseEvmDeployment = {
  pob: {
    multisig: '0x2ce7D89a9e9DF0DeAe44fdd89fAB6aCbD753d5E4',
    legacyMultisig: '0x2ce7D89a9e9DF0DeAe44fdd89fAB6aCbD753d5E4',
    deployer: '0xd60613c95F6868388AdF8bFA4e84E2c2C9aCCb15',
    team: '0x9428ca8b5fe52C33BD0BD7222d719d788B6467F4',
    universalProfile: NULL_ADDRESS,
  },
  account: {
    entryPoint: '0x5ff137d4b0fdcd49dca30c7cf57e578a026d2789',
  },
  libraries: {},
  components: {},
  utils: {
    mintingMultiCall: '0x4a82Dccd69C7Cf1C427dbA62E145937bAfD91f19',
    multiCall: '0xca11bde05977b3631167028862be2a173976ca11',
    scriptyBuilder: NULL_ADDRESS,
    onchainMetadata: '0xCf774c85617Ea916cEB13C14aC5f7EF2134b2730',
    unicode: NULL_ADDRESS,
  },
  scripty: {
    storage: NULL_ADDRESS,
    builder: NULL_ADDRESS,
    ethfsStorage: NULL_ADDRESS,
  },
  projects: {
    dead: undefined,
    hiraeth: {
      nft: '0xD7ee87178E06738f4DE88001a60331C0dE05d057',
      contractMetadata: '0x72cf9c11010f42C2fEea5C957e32F01f4312531f',
      metadata: '0x61265ca6979f8e07981ac38f09a8918c99108d10',
      logic: '0xc4019885af521ce0462f540a1a78c49dec4a296d',
      steppedMintingAuthority: '0x11dbc61F36Ef63C0a9A5ec67394baE05ADEc6519',
    },
    evenOdd: {
      evenMetadata: '0xA6a45eC03D891E5ded250AbD1Ca493B79313e332',
      oddMetadata: '0xa40488d06bBD120868E325e23De589E614dFe616',
      evenNft: '0xa27094B85Cf02170B3F8F558EE4679C6676613AE',
      oddNft: '0xe8378fF1eb827893B327456b3DBdb664a53FE65e',
      evenMintingAuthority: '0x392E9f00AdD8A56e8efd495f14e81Dd3972766e0',
      oddMintingAuthority: '0xDfDFf799299EB131B0bee68Ac1Ee2b358B814Ff2',

      perlinNoise: '0xaE37D8a98DbB640a8928C5d2B7fA50d4cA3AE202',
      metadataUtils: '0x5B2c7ae5AfF057a9547A9B6d4421c6C23098e3E6',
      metadataSvg: '0x6aCbA3207d179266A948a30216552CDB8deaFc76',
      metadataWalker: '0x5937bd142F472fBa471ad4399Bf1906343067f9A',
      metadataGenerator: '0xD1014BC92A19a4FD0a68eD13fa53a0C1ea236c90',
      evenWinningMetadata: '0xA6a45eC03D891E5ded250AbD1Ca493B79313e332',
      oddWinningMetadata: '0x44DdbEb0Ad156869d89eb354B31D96a37e5f4a28',
      evenLosingMetadata: '0xF8FF1bf52f4132DcC20Ea2442F4C83Ed249583Bc',
      oddLosingMetadata: '0xa40488d06bBD120868E325e23De589E614dFe616',
    },
    exampleWithIpfs: {
      nft: '0x2ae4a39F7FA4bF6d588a37c83e8e29E7240C4179',
      contractMetadata: '0x2EC9c1BFdC3B265DF2D9B883504B58A285607576',
      metadata: '0x9cE4f6124b176075ee84D35bF01Be88ed5Fd376A',
    },
    example: undefined,
    exeo: undefined,
    hash: undefined,
    merge: undefined,
    piano: undefined,
    london: undefined,
  },
} as const satisfies StrippedEvmDeployment;

import { baseSepolia, polygonMumbai, sepolia } from 'viem/chains';
import { CHAIN_ID_TO_CHAIN_LOOKUP } from '../chains';

export const testnetChains = [baseSepolia, sepolia, polygonMumbai];

export const isTestnetChainId = (chainId: number) => {
  for (const chain of testnetChains) {
    if (
      chain.id === chainId ||
      (CHAIN_ID_TO_CHAIN_LOOKUP as any)[chainId]?.testnet
    ) {
      return true;
    }
  }
  return false;
};

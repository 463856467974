import {
  ComponentProps,
  ElementType,
  createElement,
  forwardRef,
  memo,
} from 'react';
import { cx } from '~src/styled-system/css';

export const forwardClassName =
  <E extends ElementType>(element: E) =>
  (className?: string) => {
    return memo(
      forwardRef<E, ComponentProps<E>>((props, ref) => {
        return createElement(element, {
          ref,
          ...props,
          className: cx(className, props.className),
        });
      }),
    );
  };

export const ADDRESS_REGEX = /^0x([A-Fa-f0-9]{40})$/;
export const SELECTOR_REGEX = /^0x([A-Fa-f0-9]{8})$/;
export const TX_HASH_REGEX = /^0x([A-Fa-f0-9]{64})$/;
export const HEX_STRING_REGEX = /^0x([A-Fa-f0-9]{2})*$/;
export const WORD_REGEX = TX_HASH_REGEX;
export const CONTAINS_ADDRESS_REGEX = /0x([A-Fa-f0-9]{40})/;
export const CONTAINS_TX_HASH_REGEX = /0x([A-Fa-f0-9]{64})/;
export const TOPIC_HASH_REGEX = /^0x([A-Fa-f0-9]{64})$/;
export const TWITTER_PROFILE_REGEX =
  /^https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)$/;

import { DUMMY_ADDRESS, NULL_ADDRESS } from '@pob/shared';
import { StrippedEvmDeployment } from '../types';

export const hardhatEvmDeployment = {
  pob: {
    multisig: NULL_ADDRESS,
    legacyMultisig: NULL_ADDRESS,
    deployer: '0x70997970c51812dc3a010c7d01b50e0d17dc79c8',
    team: '0x70997970c51812dc3a010c7d01b50e0d17dc79c8',
    universalProfile: NULL_ADDRESS,
  },
  account: {
    entryPoint: NULL_ADDRESS,
  },
  libraries: {},
  components: {},
  utils: {
    mintingMultiCall: NULL_ADDRESS,
    multiCall: NULL_ADDRESS,
    scriptyBuilder: NULL_ADDRESS,
    onchainMetadata: NULL_ADDRESS,
    unicode: NULL_ADDRESS,
  },
  scripty: {
    storage: NULL_ADDRESS,
    builder: NULL_ADDRESS,
    ethfsStorage: NULL_ADDRESS,
  },
  projects: {
    hiraeth: {
      nft: '0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9',
      contractMetadata: '0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512',
      metadata: '0x5FC8d32690cc91D4c39d9d3abcBD16989F875707',
      logic: '0x5FbDB2315678afecb367f032d93F642f64180aa3',
      steppedMintingAuthority: '0x4ed7c70F96B99c776995fB64377f0d4aB3B0e1C1',
    },
    dead: undefined,
    evenOdd: undefined,
    exampleWithIpfs: undefined,
    example: undefined,
    exeo: undefined,
    hash: {
      repoUrl: 'https://github.com/proofofbeauty/HASH-monorepo',
      nft: DUMMY_ADDRESS,
      legacyNft: DUMMY_ADDRESS,
      minter: DUMMY_ADDRESS,
      registry: DUMMY_ADDRESS,
      legacyRegistry: DUMMY_ADDRESS,
      deprecatedNft: DUMMY_ADDRESS,
    },
    merge: undefined,
    piano: undefined,
    london: undefined,
  },
} as const satisfies StrippedEvmDeployment;

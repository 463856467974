import { AvailableChainId, bitcoin } from '@protocol/chains';
import { ReactNode } from 'react';
import {
  base,
  baseSepolia,
  lukso,
  luksoTestnet,
  mainnet,
  optimism,
  optimismSepolia,
  sepolia,
} from 'viem/chains';
import BaseTestnetChainImg from '~public/icons/chains/base-testnet.svg';
import BaseChainImg from '~public/icons/chains/base.svg';
import BitcoinChainImg from '~public/icons/chains/bitcoin.svg';
import EthChainImg from '~public/icons/chains/eth.svg';
import LuksoTestnetChainImg from '~public/icons/chains/lukso-testnet.svg';
import LuksoChainImg from '~public/icons/chains/lukso.svg';
import OptimismTestnetChainImg from '~public/icons/chains/optimism-testnet.svg';
import OptimismChainImg from '~public/icons/chains/optimism.svg';
import EthTestnetChainImg from '~public/icons/chains/testnet.svg';
import { Img } from '../../img';

const ChainImage = Img;

export const ICONS_BY_CHAIN_IDS = {
  [base.id]: (
    <ChainImage src={BaseChainImg} fill alt={`Icon of ${base.id} network`} />
  ),
  [baseSepolia.id]: (
    <ChainImage
      src={BaseTestnetChainImg}
      fill
      alt={`Icon of ${baseSepolia.id} network`}
    />
  ),
  [mainnet.id]: (
    <ChainImage src={EthChainImg} fill alt={`Icon of ${mainnet.id} network`} />
  ),
  [sepolia.id]: (
    <ChainImage
      src={EthTestnetChainImg}
      fill
      alt={`Icon of ${sepolia.id} network`}
    />
  ),
  [optimism.id]: (
    <ChainImage
      src={OptimismChainImg}
      fill
      alt={`Icon of ${optimism.id} network`}
    />
  ),
  [optimismSepolia.id]: (
    <ChainImage
      src={OptimismTestnetChainImg}
      fill
      alt={`Icon of ${optimismSepolia.id} network`}
    />
  ),
  [bitcoin.id]: (
    <ChainImage
      src={BitcoinChainImg}
      fill
      alt={`Icon of ${bitcoin.id} network`}
    />
  ),
  [lukso.id]: (
    <ChainImage src={LuksoChainImg} fill alt={`Icon of ${lukso.id} network`} />
  ),
  [luksoTestnet.id]: (
    <ChainImage
      src={LuksoTestnetChainImg}
      fill
      alt={`Icon of ${luksoTestnet.id} network`}
    />
  ),
} satisfies Record<AvailableChainId, ReactNode>;

import { CompositionSymmetry, Dimension, SigilMetadata } from '../types';

export const MIN_AREA_FOR_BORDER = 4;
export const TILE_DIMENSION = 3;
export const META_PIXEL_DIMENSION = 1;

export const COMPOSITION_QUADRANT_DIMENSION_IN_UNITS: Dimension = {
  w: 30 / META_PIXEL_DIMENSION,
  h: 46 / META_PIXEL_DIMENSION,
};

export const COMPOSITION_RUNTIME_DIMENSIONS = {
  [CompositionSymmetry.Quadrant]: COMPOSITION_QUADRANT_DIMENSION_IN_UNITS,
  [CompositionSymmetry.Vertical]: {
    w: COMPOSITION_QUADRANT_DIMENSION_IN_UNITS.w,
    h: COMPOSITION_QUADRANT_DIMENSION_IN_UNITS.h * 2,
  },
  [CompositionSymmetry.Horizontal]: {
    w: COMPOSITION_QUADRANT_DIMENSION_IN_UNITS.w * 2,
    h: COMPOSITION_QUADRANT_DIMENSION_IN_UNITS.h * 1,
  },
} as const satisfies Record<CompositionSymmetry, Dimension>;

export const REGION_BORDER_DIMENSION_SCALAR = 1;

export const SIGIL_ADDITION_BORDER_DIMENSION_SCALAR = 1;

export const SIGIL_DRAWABLE_DIMENSIONS = {
  default: {
    w: 6,
    h: 6,
  },
  transfer: {
    w: 4,
    h: 12,
  },
  address: {
    w: 10,
    h: 3,
  },
  txn: {
    w: 3,
    h: 3,
  },
} as const satisfies Record<SigilMetadata['type'], Dimension>;

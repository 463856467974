import { NULL_ADDRESS } from '@pob/shared';
import { StrippedEvmDeployment } from '../types';
export const optimismSepoliaEvmDeployment = {
  pob: {
    multisig: '0x2ce7D89a9e9DF0DeAe44fdd89fAB6aCbD753d5E4',
    legacyMultisig: '0x2ce7D89a9e9DF0DeAe44fdd89fAB6aCbD753d5E4',
    deployer: '0xd60613c95F6868388AdF8bFA4e84E2c2C9aCCb15',
    team: '0x9428ca8b5fe52C33BD0BD7222d719d788B6467F4',
    universalProfile: NULL_ADDRESS,
  },
  account: {
    entryPoint: NULL_ADDRESS,
  },
  libraries: {},
  components: {},
  utils: {
    mintingMultiCall: NULL_ADDRESS,
    multiCall: '0xca11bde05977b3631167028862be2a173976ca11',
    scriptyBuilder: NULL_ADDRESS,
    onchainMetadata: NULL_ADDRESS,
    unicode: NULL_ADDRESS,
  },
  scripty: {
    storage: NULL_ADDRESS,
    builder: NULL_ADDRESS,
    ethfsStorage: NULL_ADDRESS,
  },
  projects: {
    dead: undefined,
    hiraeth: undefined,
    evenOdd: undefined,
    exampleWithIpfs: undefined,
    example: undefined,
    exeo: undefined,
    hash: undefined,
    merge: undefined,
    piano: undefined,
    london: undefined,
  },
} as const satisfies StrippedEvmDeployment;

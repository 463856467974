import { css, cva } from '~src/styled-system/css';
import { styled } from '~src/styled-system/jsx';

export const baseButtonCss = css.raw({
  outline: 'none',
  background: '[none]',
  border: 'none',
  px: 'zero',
  py: 'zero',
  fontWeight: '[700]',
  letterSpacing: '[0.025rem]',
  textAlign: 'center',
  textDecoration: 'none',
  // cursor: 'pointer',
  _disabled: {
    opacity: '40',
    cursor: 'not-allowed',
  },
  fontSize: 'button',
  fontFamily: 'display',
});

export const btnTypeVariants = {
  primary: css.raw({
    hoverOverlay: 'darken',
    border: '1px solid',
    borderColor: 'brand',
    background: 'brand',
    color: 'white',
    fontWeight: 'display.normal',
  }),
  secondary: css.raw({
    hoverOverlay: 'lighten',
    background: 'black',
    borderColor: 'black',
    border: '1px solid',
    color: 'white',
    fontWeight: '[500]',
  }),
  blockverse: css.raw({
    hoverOverlay: 'lighten',
    border: '1px solid',
    borderColor: 'blockverse',
    background: 'blockverse',
    color: 'white',
    fontWeight: '[700]',
  }),
  tertiary: css.raw({
    hoverOverlay: 'darken',
    border: '1px solid',
    borderColor: 'gray.300',
    color: 'black',
  }),
  tertiarySolid: css.raw({
    hoverOverlay: 'darken',
    background: 'gray.300',
    border: '1px solid',
    borderColor: 'gray.300',
    color: 'black',
  }),
  quaternary: css.raw({
    hoverOverlay: 'darken',
    border: '1px solid',
    borderColor: 'gray.200',
    color: 'black',
  }),
  quaternarySolid: css.raw({
    hoverOverlay: 'darken',
    background: 'gray.200',
    border: '1px solid',
    borderColor: 'gray.200',
    color: 'black',
    position: 'relative',
    overflow: 'hidden',
  }),
  senary: css.raw({
    hoverOverlay: 'darken',
    border: '1px solid',
    borderColor: 'gray.100',
    color: 'gray.600',
  }),
  senarySolid: css.raw({
    hoverOverlay: 'darken',
    background: 'gray.100',
    border: '1px solid',
    borderColor: 'gray.100',
    color: 'gray.600',
  }),
  default: css.raw({
    hoverOverlay: 'darken',
    color: 'black',
  }),
} as const;

export const actionButtonCss = css.raw(baseButtonCss, {
  py: 'innerBox',
  px: 'largeBox',
  borderRadius: 'pill',
});

export const stdButtonCss = css.raw(baseButtonCss, {
  py: 'ten',
  px: 'mediumBox',
  borderRadius: 'pill',
});

export const actionWithIconButtonCss = css.raw(actionButtonCss, {
  'display': 'flex',
  'alignItems': 'center',
  // paddingLeft: '' TODO
  '& svg': {
    width: '[15px]',
    height: '[15px]',
    aspectRatio: '1:1',
    display: 'block',
  },
});

export const minorButtonCss = css.raw(baseButtonCss, {
  borderRadius: 'pill',
  py: 'threeQuartersIncrement',
  px: 'innerBox',
});

export const ctaButtonCss = css.raw(actionButtonCss, {
  display: 'block',
  boxShadow: 'default',
});

export const BaseButton = styled('button', {
  base: baseButtonCss,
});

export const iconButtonTextRecipe = cva({
  base: {
    fontFamily: 'inherit',
    color: 'inherit',
  },
  variants: {
    placement: {
      leftToText: {
        paddingLeft: 'increment',
        paddingRight: 'quarterIncrement',
      },
      rightToText: {
        paddingLeft: 'quarterIncrement',
        paddingRight: 'increment',
      },
    },
  },
});

export const squareIconButtonCss = css.raw(baseButtonCss, {
  'hoverOverlay': 'darken',
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  'borderRadius': 'squaredButton',
  'bg': 'darken.03',
  'height': 'squareIcon',
  'width': 'squareIcon',
  'aspectRatio': '1:1',
  'color': 'gray.600',
  '& svg': {
    width: '[1.2rem]',
    height: '[1.2rem]',
    display: 'block',
  },
});

export const squareMinorButtonCss = css.raw(minorButtonCss, {
  display: 'flex',
  alignItems: 'center',
  borderRadius: 'squaredButton',
  height: 'squareIcon',
});

export const IconButtonText = styled('span', iconButtonTextRecipe);

export const ButtonRow = styled('div', {
  base: {
    display: 'flex',
    alignItems: 'center',
    gap: 'innerBox',
  },
});

export const ActionButton = styled('button', {
  base: actionButtonCss,
  variants: {
    btnType: btnTypeVariants,
  },
  defaultVariants: {
    btnType: 'default',
  },
});

export const StdButton = styled('button', {
  base: stdButtonCss,
  variants: {
    btnType: btnTypeVariants,
  },
  defaultVariants: {
    btnType: 'default',
  },
});

export const MinorButton = styled('button', {
  base: minorButtonCss,
  variants: {
    btnType: btnTypeVariants,
  },
  defaultVariants: {
    btnType: 'default',
  },
});

export const CTAButton = styled('button', {
  base: ctaButtonCss,
  variants: {
    btnType: btnTypeVariants,
  },
  defaultVariants: {
    btnType: 'default',
  },
});

export const ActionWithIconButton = styled('button', {
  base: actionWithIconButtonCss,
  variants: {
    btnType: btnTypeVariants,
  },
  defaultVariants: {
    btnType: 'default',
  },
});

export const SquareIconButton = styled('button', {
  base: squareIconButtonCss,
});

export const SquareMinorButton = styled('button', {
  base: squareMinorButtonCss,
  variants: {
    btnType: btnTypeVariants,
  },
  defaultVariants: {
    btnType: 'default',
  },
});

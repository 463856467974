import type { BlockverseProjectId } from '@pob/blockverse';
import type { Evm } from '@pob/shared';
import { base } from 'viem/chains';
import type { BlockverseObjId } from '~src/shared-apps/blockverse/types';
import { serializeId } from '../../../utils/strings/serializeId';
import type { BlockverseContextWithId } from '../types';
import type { BlockverseContext } from '../types/context';
import { getBlockverseContextHash } from '../utils/getBlockverseContextHash';

export const BLOCKVERSE_CONTEXTS_DEFAULTS = [
  [
    [
      'hiraeth',
      base.id,
      '0x4f9052feb5b3b20f79fabc175438824badebcf840e478f36b0f3731ae524e14b' as Evm.TxnHash,
    ],
    {
      type: 'search',
      timestamp: '2023-08-09T05:42:03Z',
      description: 'Base Paint Deployment',
      source: null,
      to: null,
      temporal: {
        dateUpdated: 1716353876112,
        ttl: null,
      },
    },
  ],
  [
    [
      'hiraeth',
      base.id,
      '0x78a90a565cdf019bcb7fca7ae4f713a6acc16fa4b35b61d620fc00f9cae40514' as Evm.TxnHash,
    ],
    {
      type: 'search',
      description: "Studio's 1st Txn on Eth",
      timestamp: '2023-08-13T22:56:33Z',
      source: null,
      to: null,
      temporal: {
        dateUpdated: 1716353876112,
        ttl: null,
      },
    },
  ],
] as [BlockverseObjId<BlockverseProjectId>, BlockverseContext][];

// satisfies [
//   BlockverseContextObjectKey<BlockverseProjectId>,
//   BlockverseContextWithoutId,
// ][];

export const BLOCKVERSE_OBJECT_CONTEXTS_DEFAULTS_MAP = new Map(
  BLOCKVERSE_CONTEXTS_DEFAULTS.map(([objId, context]) => [
    serializeId(objId),
    {
      ...context,
      hash: getBlockverseContextHash(context),
      id: [...objId, context.type, getBlockverseContextHash(context)],
    } as BlockverseContextWithId,
  ]),
);

import { Cord, getRandomFactory } from '@pob/art-utils';
import {
  getHarrisonModel,
  getHarrisonPreImage,
} from '../algorithms/model/harrison';
import {
  initHarrisonRuntime,
  runHarrisonWasm,
} from '../algorithms/runtime/wasm-harrison';
import { Composition } from '../composition';
import { SIGIL_DRAWABLE_DIMENSIONS } from '../composition/constants';
import { getSigilBorderDimensionScalar } from '../composition/sigil';
import {
  ArtState,
  Dimension,
  PatternMetadata,
  Quadrant,
  RegionType,
  SigilMetadata,
} from '../types';
import { getArea, getBoundingRectInPixels } from '../utils/2d';
import {
  COMPOSITION_TO_PIXELS,
  ImageDrawnState,
  TRANSPARENT_COLOR,
} from './constants';
import { ImageState } from './types';

const SIGIL_INSCRIBED_COLOR = 3;
const SIGIL_BACKGROUND_COLOR = -6;
const SIGIL_BORDER_INSCRIBED_COLOR = 1;
const SIGIL_BORDER = [SIGIL_BACKGROUND_COLOR, 4, -4, -8];
const SIGIL_BORDER_DIMENSION = COMPOSITION_TO_PIXELS;

export const getSigilsRenderer = async (
  artState: ArtState,
  composition: Composition,
  imageState: ImageState,
  patternMetadata: PatternMetadata,
) => {
  const sigilPixelsMetadata = new Map<
    number,
    Awaited<ReturnType<typeof getSigilWithBorder>>
  >();

  for (let i = 1; i < composition.regions.numRegions + 1; ++i) {
    const regionMetadata = composition.regions.markerMetadata.get(i);
    if (regionMetadata!.regionType !== RegionType.Sigil) {
      continue;
    }
    const sigilIndex = composition.regions.regionMarkerToSigil.get(i)!;
    const sigilMetadata = artState.txn.sigils[sigilIndex];

    const sigilBorderDimensionScalar =
      getSigilBorderDimensionScalar(sigilMetadata);

    const { sigil, sigilDimension } = await getSigilFromMetadata(
      artState.wasm.harrison.code,
      sigilMetadata,
      patternMetadata,
    );

    sigilPixelsMetadata.set(
      i,
      await getSigilWithBorder(
        sigilMetadata.type,
        artState,
        sigil,
        patternMetadata,
        sigilMetadata.randomSource << 1,
        sigilDimension,
        sigilBorderDimensionScalar,
      ),
    );
  }

  const drawSigil = (
    sigilType: SigilMetadata['type'],
    symbol: Int8Array,
    symbolDimension: Dimension,
    symbolBorderDimensionScalar: number,
    symbolOrigin: Cord,
  ) => {
    const { x: symbolOriginX, y: symbolOriginY } = symbolOrigin;

    for (let dx = 0; dx < symbolDimension.w; ++dx) {
      for (let dy = 0; dy < symbolDimension.h; ++dy) {
        const pixelX = symbolOriginX + dx;
        const pixelY = symbolOriginY + dy;
        if (pixelX < 0 || pixelX >= imageState.w) {
          continue;
        }
        const i = pixelX + pixelY * imageState.w;
        let symbolX = dx;
        let symbolY = dy;
        const symbolPixel = symbol[symbolX + symbolY * symbolDimension.w];
        if (
          dx >= symbolBorderDimensionScalar &&
          dx <= symbolDimension.w - symbolBorderDimensionScalar &&
          dy >= symbolBorderDimensionScalar &&
          dy <= symbolDimension.h - symbolBorderDimensionScalar
        ) {
          if (sigilType === 'address') {
            imageState.drawn[i] =
              symbolPixel === 0
                ? ImageDrawnState.DrawnRegionAsSigilAddress
                : ImageDrawnState.DrawnRegionAsSigilAddressCrest;
          } else if (sigilType === 'txn') {
            imageState.drawn[i] = ImageDrawnState.DrawnRegionAsSigilTxn;
          } else {
            imageState.drawn[i] = ImageDrawnState.DrawnRegionAsSigil;
          }
        } else {
          if (sigilType === 'address') {
            imageState.drawn[i] =
              ImageDrawnState.DrawnRegionAsSigilAddressBorder;
          } else if (sigilType === 'txn') {
            imageState.drawn[i] = ImageDrawnState.DrawnRegionAsSigilTxnBorder;
          } else {
            imageState.drawn[i] = ImageDrawnState.DrawnRegionAsSigilBorder;
          }
        }
        imageState.d[i] = symbolPixel;
      }
    }
  };

  return {
    render: () => {
      for (
        let regionMarker = 1;
        regionMarker < composition.regions.numRegions + 1;
        ++regionMarker
      ) {
        const regionMetadata =
          composition.regions.markerMetadata.get(regionMarker);
        if (regionMetadata!.regionType !== RegionType.Sigil) {
          continue;
        }

        const {
          sigilBorderDimensionScalar,
          sigilWithBorder,
          sigilWithBorderDimension,
          sigilType,
        } = sigilPixelsMetadata.get(regionMarker)!;
        const adjustedBoundingRectInPixels = getBoundingRectInPixels(
          regionMetadata!.boundingRect,
          COMPOSITION_TO_PIXELS,
        );

        drawSigil(
          sigilType,
          sigilWithBorder,
          sigilWithBorderDimension,
          sigilBorderDimensionScalar,
          {
            x: adjustedBoundingRectInPixels.topLeft.x,
            y: adjustedBoundingRectInPixels.topLeft.y,
          },
        );
      }
    },
  };
};

export const getSigilFromMetadata = async (
  wasmCode: any,
  sigilMetadata: SigilMetadata,
  patternMetadata: PatternMetadata,
) => {
  const sigilDimension: Dimension = {
    w: SIGIL_DRAWABLE_DIMENSIONS[sigilMetadata.type].w * COMPOSITION_TO_PIXELS,
    h: SIGIL_DRAWABLE_DIMENSIONS[sigilMetadata.type].h * COMPOSITION_TO_PIXELS,
  };

  let sigil = new Int8Array(0);
  if (sigilMetadata.type === 'default') {
    sigil = (
      await getSigilFromPatternMetadata(
        wasmCode,
        patternMetadata,
        sigilMetadata.randomSource,
        sigilDimension.w,
      )
    ).sigil;
    // console.log(`Sigil (default) generated.`);
  } else if (sigilMetadata.type === 'transfer') {
    sigil = (
      await getTransferSigilFromPatternMetadata(
        wasmCode,
        patternMetadata,
        sigilMetadata.toRandomSource,
        sigilMetadata.fromRandomSource,
        sigilMetadata.randomSource,
        sigilDimension,
      )
    ).sigil;
    // console.log(`Sigil (transfer) generated.`);
  } else if (sigilMetadata.type === 'address') {
    sigil = (
      await getAddressSigilFromPatternMetadata(
        wasmCode,
        patternMetadata,
        sigilMetadata.randomSource,
        sigilDimension,
      )
    ).sigil;
    // console.log(`Sigil (address) generated.`);
  } else if (sigilMetadata.type === 'txn') {
    sigil = (
      await getSigilFromPatternMetadata(
        wasmCode,
        patternMetadata,
        sigilMetadata.randomSource,
        sigilDimension.w,
      )
    ).sigil;
    // console.log(`Sigil (txn) generated.`);
  }
  return {
    sigil,
    sigilDimension,
  };
};

enum SigilType {
  FullSymmetric = 0, // corner joins and the edges are all the same
  DifferingCorners = 1, // edges are the same, but the corners are different
  DifferingCornersAndEdges = 2, // edges (along the x and y ) and corners are different
  DifferingCornersAndEdgesX = 3, // x edge and corner are the same, y edge is different
  DifferingCornersAndEdgesY = 4, // y edge and corner are the same, x edge is different
  DifferentQuadrants = 5, // diagonally opposite quadrants are different
  Chaos1 = 6,
  Chaos2 = 7,
}

export const getSigilFromPatternMetadata = async (
  wasmCode: any,
  patternMetadata: PatternMetadata,
  selector: number,
  sigilDimensionScalar: number,
) => {
  const { random, weightedRandom } = getRandomFactory(selector + 'sigil');
  const sigil = new Int8Array(sigilDimensionScalar * sigilDimensionScalar);

  if (selector === 0) {
    for (let i = 0; i < sigil.length; ++i) {
      const x = Math.abs(
        (i % sigilDimensionScalar) - Math.floor(sigilDimensionScalar / 2) + 1,
      );
      const y = Math.abs(
        Math.floor(i / sigilDimensionScalar) -
          Math.floor(sigilDimensionScalar / 2) +
          1,
      );
      const depth = Math.max(x, y);
      sigil[i] = -3 - (Math.floor(sigilDimensionScalar / 2) - depth);
    }
    return {
      sigil,
    };
  }

  const sigilType = weightedRandom({
    [SigilType.FullSymmetric]: 0.25,
    [SigilType.DifferingCorners]: 0.2,
    [SigilType.DifferingCornersAndEdges]: 0.1,
    [SigilType.DifferingCornersAndEdgesX]: 0.1,
    [SigilType.DifferingCornersAndEdgesY]: 0.15,
    [SigilType.DifferentQuadrants]: 0.1,
    [SigilType.Chaos1]: 0.05,
    [SigilType.Chaos2]: 0.05,
  });

  const sigilQuadrantDimension = Math.ceil(sigilDimensionScalar / 2);
  const sigilComponentDimension = 2 + Math.floor(random() * 6);
  const numSigilComponents = Math.ceil(
    sigilQuadrantDimension / sigilComponentDimension,
  );
  const numSigilComponentsInPattern = numSigilComponents * 3;
  const patternWidth = numSigilComponentsInPattern * sigilComponentDimension;
  const patternHeight = sigilComponentDimension;

  const harrisonModel = getHarrisonModel(patternMetadata, {
    metaPixelDimensionX: 1,
    metaPixelDimensionY: 1,
    metaPixels: patternMetadata.colors.map((color, i) => {
      return new Uint8Array([i]);
    }),
  });

  const randomBlankWeight = weightedRandom({
    [0]: 0.25,
    [0.05]: 0.25,
    [0.1]: 0.34,
    [0.25]: 0.14,
    [0.5]: 0.02,
  });

  const blankPreImageXAxis: boolean[] = [];
  for (let i = 0; i < patternWidth; ++i) {
    blankPreImageXAxis.push(random() < randomBlankWeight!);
  }
  const preImage = getHarrisonPreImage({
    randomSource: selector,
    model: harrisonModel,
    width: patternWidth,
    height: patternHeight,
    getMetaPixel: (x, y) => {
      if (blankPreImageXAxis[x]) {
        return 0;
      }
      return null;
    },
  });

  const harrisonRuntime = await initHarrisonRuntime({
    outputWidth: patternWidth,
    outputHeight: patternHeight,
    model: harrisonModel,
    randomSource: selector,
    neighborsClimbRate: 4,
    scoreSearchDimension: 2,
    numPolishingLoops: 2,
    preImage,
    wasmCode,
  });

  // const startHarrisonWasmTime = performance.now();
  runHarrisonWasm(harrisonRuntime);
  // const endHarrisonWasmTime = performance.now();
  // console.log(
  //   `Sigil Wasm Harrison took ${endHarrisonWasmTime - startHarrisonWasmTime} milliseconds.`,
  // );
  harrisonRuntime.harrisonWasmInstance.saveToOutput();
  const output = harrisonRuntime.getOutput();

  const getSigilQuadrant = (quadrant: Quadrant) => {
    const sigilQuadrant = new Int8Array(
      sigilQuadrantDimension * sigilQuadrantDimension,
    );

    for (let scx = 0; scx < numSigilComponents; ++scx) {
      for (let scy = 0; scy < numSigilComponents; ++scy) {
        if (scx === scy) {
          let si = scx;
          if (sigilType === SigilType.DifferentQuadrants) {
            si = scx + numSigilComponents * quadrant;
          }
          for (let sy = 0; sy < sigilComponentDimension; ++sy) {
            for (let sx = 0; sx <= sy; ++sx) {
              const pixel =
                output[
                  sx + si * sigilComponentDimension + sy * patternWidth
                ] !== 0
                  ? SIGIL_INSCRIBED_COLOR
                  : SIGIL_BACKGROUND_COLOR;
              sigilQuadrant[
                sx +
                  scx * sigilComponentDimension +
                  (sy + scy * sigilComponentDimension) * sigilQuadrantDimension
              ] = pixel;
              sigilQuadrant[
                sy +
                  scy * sigilComponentDimension +
                  (sx + scx * sigilComponentDimension) * sigilQuadrantDimension
              ] = pixel;
            }
          }
        }

        if (sigilType === SigilType.Chaos1 || sigilType === SigilType.Chaos2) {
          let si = scx;
          if (sigilType === SigilType.Chaos2) {
            si = scy;
          }
          if (scy > (numSigilComponents - 1) / 2) {
            si = scy + numSigilComponents;
          }
          if (
            sigilType === SigilType.Chaos2 &&
            scx > (numSigilComponents - 1) / 2
          ) {
            si = scx + numSigilComponents;
          }

          for (let sx = 0; sx < sigilComponentDimension; ++sx) {
            for (let sy = 0; sy < sigilComponentDimension; ++sy) {
              sigilQuadrant[
                sx +
                  scx * sigilComponentDimension +
                  (sy + scy * sigilComponentDimension) * sigilQuadrantDimension
              ] =
                output[
                  sx + si * sigilComponentDimension + sy * patternWidth
                ] !== 0
                  ? SIGIL_INSCRIBED_COLOR
                  : SIGIL_BACKGROUND_COLOR;
            }
          }
        } else if (scx < scy) {
          let si = scx;
          if (sigilType === SigilType.DifferentQuadrants) {
            si = scx + numSigilComponents * quadrant;
          }
          if (
            sigilType === SigilType.DifferingCorners ||
            sigilType === SigilType.DifferingCornersAndEdges
          ) {
            si = scx + numSigilComponents;
          }
          if (sigilType === SigilType.DifferingCornersAndEdgesX) {
            si = scx + numSigilComponents * 2;
          }
          for (let sx = 0; sx < sigilComponentDimension; ++sx) {
            for (let sy = 0; sy < sigilComponentDimension; ++sy) {
              sigilQuadrant[
                sx +
                  scx * sigilComponentDimension +
                  (sy + scy * sigilComponentDimension) * sigilQuadrantDimension
              ] =
                output[
                  sx + si * sigilComponentDimension + sy * patternWidth
                ] !== 0
                  ? SIGIL_INSCRIBED_COLOR
                  : SIGIL_BACKGROUND_COLOR;
              if (sigilType === SigilType.DifferingCornersAndEdgesX) {
                si = scx;
              }
              if (
                sigilType === SigilType.DifferingCornersAndEdges ||
                sigilType === SigilType.DifferingCornersAndEdgesY
              ) {
                si = scx + numSigilComponents * 2;
              }
              sigilQuadrant[
                sy +
                  scy * sigilComponentDimension +
                  (sx + scx * sigilComponentDimension) * sigilQuadrantDimension
              ] =
                output[
                  sx + si * sigilComponentDimension + sy * patternWidth
                ] !== 0
                  ? SIGIL_INSCRIBED_COLOR
                  : SIGIL_BACKGROUND_COLOR;
            }
          }
        }
      }
    }
    return sigilQuadrant;
  };
  const sigilQuadrant = getSigilQuadrant(Quadrant.TopLeft);
  const opposingSigilQuadrant = getSigilQuadrant(Quadrant.TopRight);

  for (let x = 0; x < sigilQuadrantDimension; ++x) {
    for (let y = 0; y < sigilQuadrantDimension; ++y) {
      sigil[x + y * sigilDimensionScalar] =
        sigilQuadrant[x + y * sigilQuadrantDimension];
      sigil[
        sigilDimensionScalar -
          1 -
          x +
          (sigilDimensionScalar - 1 - y) * sigilDimensionScalar
      ] = sigilQuadrant[x + y * sigilQuadrantDimension];

      sigil[sigilDimensionScalar - 1 - x + y * sigilDimensionScalar] = (
        sigilType === SigilType.DifferentQuadrants
          ? opposingSigilQuadrant
          : sigilQuadrant
      )[x + y * sigilQuadrantDimension];
      sigil[x + (sigilDimensionScalar - 1 - y) * sigilDimensionScalar] = (
        sigilType === SigilType.DifferentQuadrants
          ? opposingSigilQuadrant
          : sigilQuadrant
      )[x + y * sigilQuadrantDimension];
    }
  }

  return {
    sigil,
  };
};

const getAddressSigilFromPatternMetadata = async (
  wasmCode: any,
  patternMetadata: PatternMetadata,
  randomSource: number,
  sigilDimension: Dimension,
) => {
  const { random, weightedRandom } = getRandomFactory(
    randomSource + 'address-sigil',
  );
  const sigil = new Int8Array(getArea(sigilDimension));

  const sigilHalfDimension: Dimension = {
    w: Math.floor(sigilDimension.w / 2),
    h: sigilDimension.h,
  };
  const sigilComponentDimension = 4; //+ Math.floor(random() * 6);
  const numSigilComponents = Math.ceil(
    sigilHalfDimension.w / sigilComponentDimension,
  );

  const numSigilComponentsInPattern =
    numSigilComponents *
    Math.ceil(sigilHalfDimension.h / sigilComponentDimension);
  const patternWidth = numSigilComponentsInPattern * sigilComponentDimension;
  const patternHeight = sigilComponentDimension;

  const harrisonModel = getHarrisonModel(patternMetadata, {
    metaPixelDimensionX: 1,
    metaPixelDimensionY: 1,
    metaPixels: patternMetadata.colors.map((color, i) => {
      return new Uint8Array([i]);
    }),
  });

  // const randomBlankWeight = weightedRandom({
  //   [0]: 0.25,
  //   [0.05]: 0.25,
  //   [0.1]: 0.34,
  //   [0.25]: 0.14,
  //   [0.5]: 0.02,
  // });

  // const blankPreImageXAxis: boolean[] = [];
  // for (let i = 0; i < patternWidth; ++i) {
  //   blankPreImageXAxis.push(random() < randomBlankWeight!);
  // }
  const preImage = getHarrisonPreImage({
    randomSource,
    model: harrisonModel,
    width: patternWidth,
    height: patternHeight,
    getMetaPixel: (x, y) => {
      // if (blankPreImageXAxis[x]) {
      //   return 0;
      // }
      return null;
    },
  });

  const harrisonRuntime = await initHarrisonRuntime({
    outputWidth: patternWidth,
    outputHeight: patternHeight,
    model: harrisonModel,
    randomSource,
    neighborsClimbRate: 4,
    scoreSearchDimension: 2,
    numPolishingLoops: 2,
    preImage,
    wasmCode,
  });

  // const startHarrisonWasmTime = performance.now();
  runHarrisonWasm(harrisonRuntime);
  // const endHarrisonWasmTime = performance.now();
  // console.log(
  //   `Sigil Wasm Harrison took ${endHarrisonWasmTime - startHarrisonWasmTime} milliseconds.`,
  // );
  harrisonRuntime.harrisonWasmInstance.saveToOutput();
  const output = harrisonRuntime.getOutput();

  const getSigilQuadrant = (quadrant: Quadrant) => {
    const sigilQuadrant = new Int8Array(getArea(sigilHalfDimension));

    for (
      let scx = 0;
      scx < Math.ceil(sigilHalfDimension.w / sigilComponentDimension);
      ++scx
    ) {
      for (
        let scy = 0;
        scy < Math.ceil(sigilHalfDimension.h / sigilComponentDimension);
        ++scy
      ) {
        let isOutsideOfCrest = randomSource === 0;
        let si = scy;
        if (scx > scy) {
          si = numSigilComponents - 1 - scx + scy * numSigilComponents;
        } else {
          isOutsideOfCrest = true;
          si = scy;
        }
        for (let sy = 0; sy < sigilComponentDimension; ++sy) {
          for (let sx = 0; sx < sigilComponentDimension; ++sx) {
            const pixel =
              output[sx + si * sigilComponentDimension + sy * patternWidth] !==
              0
                ? isOutsideOfCrest
                  ? 0
                  : 1
                : SIGIL_BACKGROUND_COLOR;
            sigilQuadrant[
              sx +
                scx * sigilComponentDimension +
                (sy + scy * sigilComponentDimension) * sigilHalfDimension.w
            ] = pixel;
          }
        }
      }
    }
    return sigilQuadrant;
  };
  const sigilQuadrant = getSigilQuadrant(Quadrant.TopLeft);

  for (let x = 0; x < sigilHalfDimension.w; ++x) {
    for (let y = 0; y < sigilHalfDimension.h; ++y) {
      sigil[x + y * sigilDimension.w] =
        sigilQuadrant[x + y * sigilHalfDimension.w];
      sigil[sigilDimension.w - 1 - x + y * sigilDimension.w] =
        sigilQuadrant[x + y * sigilHalfDimension.w];
    }
  }

  return {
    sigil,
  };
};

const DIRECTIONAL_SIGIL_COMPONENTS: [number, number, number, number][] = [
  [0, 0, 0, 0],
  [0, 0, 0, 1],
  [0, 0, 1, 0],
  [0, 0, 1, 1],
  [0, 1, 0, 0],
  [0, 1, 0, 1],
  [0, 1, 1, 0],
  [0, 1, 1, 1],
  [1, 0, 0, 0],
  [1, 0, 0, 1],
  [1, 0, 1, 0],
  [1, 0, 1, 1],
  [1, 1, 0, 0],
  [1, 1, 0, 1],
  [1, 1, 1, 0],
  [1, 1, 1, 1],
];

const getDirectionSigilFromPatternMetadata = async (
  randomSource: number,
  sigilDimension: Dimension,
) => {
  const { randomInArr } = getRandomFactory(randomSource + 'direction-sigil');
  const sigilHalfDimension = Math.ceil(sigilDimension.w / 2);
  const sigilComponentDimension = 2;
  const numSigilComponents = Math.ceil(
    sigilHalfDimension / sigilComponentDimension,
  );
  const numSigilComponentsHeight = Math.ceil(
    sigilDimension.h / sigilComponentDimension,
  );
  const numSigilComponentsHeightForPattern = Math.floor(
    numSigilComponentsHeight / 2,
  );
  const numSigilComponentsInPattern =
    numSigilComponents * numSigilComponentsHeightForPattern;
  const patternWidth = numSigilComponentsInPattern * sigilComponentDimension;
  const patternHeight = sigilComponentDimension;

  const output = new Int8Array(patternWidth * patternHeight);
  for (let si = 0; si < numSigilComponentsInPattern; ++si) {
    const [sigilComponent] = randomInArr(DIRECTIONAL_SIGIL_COMPONENTS);
    for (let y = 0; y < sigilComponentDimension; ++y) {
      for (let x = 0; x < sigilComponentDimension; ++x) {
        output[x + si * sigilComponentDimension + y * patternWidth] =
          sigilComponent[x + y * sigilComponentDimension];
      }
    }
  }

  const sigil = new Int8Array(sigilDimension.w * sigilDimension.h);

  for (let scx = 0; scx < numSigilComponents; ++scx) {
    for (let scy = 0; scy < numSigilComponentsHeight; ++scy) {
      for (let sx = 0; sx < sigilComponentDimension; ++sx) {
        for (let sy = 0; sy < sigilComponentDimension; ++sy) {
          let si = (scy - scx) % numSigilComponentsHeightForPattern;
          if (si < 0) {
            si += numSigilComponentsHeightForPattern;
          }
          sigil[
            sx +
              scx * sigilComponentDimension +
              (sy + scy * sigilComponentDimension) * sigilDimension.w
          ] =
            output[sx + si * sigilComponentDimension + sy * patternWidth] !== 0
              ? SIGIL_BORDER_INSCRIBED_COLOR
              : SIGIL_BACKGROUND_COLOR;
          sigil[
            sigilDimension.w -
              1 -
              sx -
              scx * sigilComponentDimension +
              (sy + scy * sigilComponentDimension) * sigilDimension.w
          ] =
            output[sx + si * sigilComponentDimension + sy * patternWidth] !== 0
              ? SIGIL_BORDER_INSCRIBED_COLOR
              : SIGIL_BACKGROUND_COLOR;
        }
      }
    }
  }

  return {
    sigil,
  };
};

const TRANSFER_SIGIL_BORDER: number[] = [];

const getTransferSigilFromPatternMetadata = async (
  wasmCode: any,
  patternMetadata: PatternMetadata,
  toRandomSource: number,
  fromRandomSource: number,
  tokenRandomSource: number,
  sigilDimension: Dimension,
) => {
  const transferSigilBorderLength = TRANSFER_SIGIL_BORDER.length;
  const addressSigilDimensionScalar =
    sigilDimension.w - transferSigilBorderLength * 2;

  const toSigil = await getSigilFromPatternMetadata(
    wasmCode,
    patternMetadata,
    toRandomSource,
    addressSigilDimensionScalar,
  );

  const fromSigil = await getSigilFromPatternMetadata(
    wasmCode,
    patternMetadata,
    fromRandomSource,
    addressSigilDimensionScalar,
  );

  const directionSigilDimension = {
    w: addressSigilDimensionScalar,
    h: sigilDimension.h - 2 * sigilDimension.w,
  };
  const directionSigil = await getDirectionSigilFromPatternMetadata(
    tokenRandomSource,
    directionSigilDimension,
  );

  const sigil = new Int8Array(sigilDimension.w * sigilDimension.h);

  for (let i = 0; i < transferSigilBorderLength; ++i) {
    for (let j = i; j < sigilDimension.w - i; ++j) {
      sigil[j + i * sigilDimension.w] = TRANSFER_SIGIL_BORDER[i];
      sigil[j + (sigilDimension.h - i - 1) * sigilDimension.w] =
        TRANSFER_SIGIL_BORDER[i];
    }
    for (let j = i; j < sigilDimension.h - i; ++j) {
      sigil[i + j * sigilDimension.w] = TRANSFER_SIGIL_BORDER[i];
      sigil[sigilDimension.w - i - 1 + j * sigilDimension.w] =
        TRANSFER_SIGIL_BORDER[i];
    }
    for (let j = 1; j < sigilDimension.w - 1; ++j) {
      sigil[j + (sigilDimension.w - i - 1) * sigilDimension.w] =
        TRANSFER_SIGIL_BORDER[i];
    }
  }

  for (let i = 0; i < transferSigilBorderLength; ++i) {
    for (let j = 1; j < sigilDimension.w - 1; ++j) {
      sigil[j + (sigilDimension.w - i - 1) * sigilDimension.w] =
        TRANSFER_SIGIL_BORDER[i];
      sigil[j + (sigilDimension.h + i - sigilDimension.w) * sigilDimension.w] =
        TRANSFER_SIGIL_BORDER[i];
    }
  }

  for (
    let i = 0;
    i < addressSigilDimensionScalar * addressSigilDimensionScalar;
    ++i
  ) {
    const sigilX =
      (i % addressSigilDimensionScalar) + transferSigilBorderLength;
    const sigilY =
      Math.floor(i / addressSigilDimensionScalar) + transferSigilBorderLength;
    sigil[sigilX + sigilY * sigilDimension.w] = fromSigil.sigil[i];
    sigil[
      sigilX +
        (sigilY + (sigilDimension.h - sigilDimension.w)) * sigilDimension.w
    ] = toSigil.sigil[i];
  }
  for (
    let i = 0;
    i < directionSigilDimension.w * directionSigilDimension.h;
    ++i
  ) {
    const sigilX = (i % directionSigilDimension.w) + transferSigilBorderLength;
    const sigilY = Math.floor(i / directionSigilDimension.w) + sigilDimension.w;
    sigil[sigilX + sigilY * sigilDimension.w] = directionSigil.sigil[i];
  }
  return {
    sigil,
  };
};

const getSigilWithBorder = async (
  sigilType: SigilMetadata['type'],
  artState: ArtState,
  sigil: Int8Array,
  patternMetadata: PatternMetadata,
  selector: number,
  sigilDimension: Dimension,
  borderUnits: number,
) => {
  const sigilComponentDimension = SIGIL_BORDER_DIMENSION;
  const sigilBorderDimensionScalar = borderUnits * sigilComponentDimension;
  const sigilWithBorderDimension: Dimension = {
    w: sigilDimension.w + 2 * sigilBorderDimensionScalar,
    h: sigilDimension.h + 2 * sigilBorderDimensionScalar,
  };
  const sigilWithBorder =
    borderUnits === 0
      ? sigil
      : new Int8Array(sigilWithBorderDimension.h * sigilWithBorderDimension.w);

  if (borderUnits > 0) {
    for (let x = 0; x < sigilDimension.w; ++x) {
      for (let y = 0; y < sigilDimension.h; ++y) {
        const i = x + y * sigilDimension.w;
        sigilWithBorder[
          x +
            sigilBorderDimensionScalar +
            (y + sigilBorderDimensionScalar) * sigilWithBorderDimension.w
        ] = sigil[i];
      }
    }

    for (let bi = 0; bi < SIGIL_BORDER.length; ++bi) {
      if (SIGIL_BORDER[bi] === TRANSPARENT_COLOR) {
        continue;
      }
      for (let d = bi; d < sigilWithBorderDimension.h - bi; ++d) {
        const x = bi;
        const y = d;
        const leftI = x + y * sigilWithBorderDimension.w;
        sigilWithBorder[leftI] = SIGIL_BORDER[bi] + 1;
        const rightI =
          sigilWithBorderDimension.w - 1 - bi + y * sigilWithBorderDimension.w;
        sigilWithBorder[rightI] = SIGIL_BORDER[bi];
      }
      for (let d = bi; d < sigilWithBorderDimension.w - bi; ++d) {
        const x = d;
        const y = bi;
        const topI = x + y * sigilWithBorderDimension.w;
        sigilWithBorder[topI] = SIGIL_BORDER[bi] + 1;
        const bottomI =
          (sigilWithBorderDimension.h - 1 - bi) * sigilWithBorderDimension.w +
          d;
        sigilWithBorder[bottomI] = SIGIL_BORDER[bi] - 1;
      }
    }

    if (borderUnits > 1) {
      const numSigilComponents = borderUnits - 1;
      const patternWidth = numSigilComponents * sigilComponentDimension;
      const patternHeight = sigilComponentDimension;
      const harrisonModel = getHarrisonModel(patternMetadata, {
        metaPixelDimensionX: 1,
        metaPixelDimensionY: 1,
        metaPixels: patternMetadata.colors.map((color, i) => {
          return new Uint8Array([i]);
        }),
      });

      const preImage = getHarrisonPreImage({
        randomSource: selector,
        model: harrisonModel,
        width: patternWidth,
        height: patternHeight,
        getMetaPixel: (x, y) => {
          if (y === sigilComponentDimension - 1) {
            return 0;
          }
          return null;
        },
      });

      const harrisonRuntime = await initHarrisonRuntime({
        outputWidth: patternWidth,
        outputHeight: patternHeight,
        model: harrisonModel,
        randomSource: selector,
        neighborsClimbRate: 4,
        scoreSearchDimension: 2,
        numPolishingLoops: 2,
        preImage,
        wasmCode: artState.wasm.harrison.code,
      });

      // const startHarrisonWasmTime = performance.now();
      runHarrisonWasm(harrisonRuntime);
      // const endHarrisonWasmTime = performance.now();
      // console.log(
      //   `Sigil Wasm Harrison took ${endHarrisonWasmTime - startHarrisonWasmTime} milliseconds.`,
      // );
      harrisonRuntime.harrisonWasmInstance.saveToOutput();
      const output = harrisonRuntime.getOutput();

      for (let bci = 0; bci < numSigilComponents; bci++) {
        // top and bottom borders
        const borderOriginX = (bci + 1) * sigilComponentDimension;
        const borderOriginY = (bci + 1) * sigilComponentDimension;
        for (let y = 0; y < sigilComponentDimension; ++y) {
          for (
            let x = y;
            x <
            (sigilWithBorderDimension.w -
              (1 + bci) * 2 * sigilComponentDimension) /
              2;
            ++x
          ) {
            let i =
              x +
              borderOriginX +
              (y + borderOriginY) * sigilWithBorderDimension.w;
            const symbolIndex = bci;
            const symbolX = x % sigilComponentDimension;
            const symbolY = y;
            const symbolPixel =
              output[
                symbolX +
                  symbolIndex * sigilComponentDimension +
                  symbolY * patternWidth
              ] !== 0
                ? SIGIL_BORDER_INSCRIBED_COLOR
                : SIGIL_BACKGROUND_COLOR;
            sigilWithBorder[i] = symbolPixel;
            i =
              sigilWithBorderDimension.w -
              1 -
              x -
              borderOriginX +
              (y + borderOriginY) * sigilWithBorderDimension.w;
            sigilWithBorder[i] = symbolPixel;
            i =
              x +
              borderOriginX +
              (sigilWithBorderDimension.h - 1 - y - borderOriginY) *
                sigilWithBorderDimension.w;
            sigilWithBorder[i] = symbolPixel;
            i =
              sigilWithBorderDimension.w -
              1 -
              x -
              borderOriginX +
              (sigilWithBorderDimension.h - 1 - y - borderOriginY) *
                sigilWithBorderDimension.w;
            sigilWithBorder[i] = symbolPixel;
          }
        }
        for (let x = 0; x < sigilComponentDimension; ++x) {
          for (
            let y = x;
            y <
            (sigilWithBorderDimension.h -
              (1 + bci) * 2 * sigilComponentDimension) /
              2;
            ++y
          ) {
            let i =
              x +
              borderOriginX +
              (y + borderOriginY) * sigilWithBorderDimension.w;
            const symbolIndex = bci;
            const symbolX = x;
            const symbolY = y % sigilComponentDimension;
            const symbolPixel =
              output[
                symbolY +
                  symbolIndex * sigilComponentDimension +
                  symbolX * patternWidth
              ] !== 0
                ? SIGIL_BORDER_INSCRIBED_COLOR
                : SIGIL_BACKGROUND_COLOR;
            sigilWithBorder[i] = symbolPixel;
            i =
              sigilWithBorderDimension.w -
              1 -
              x -
              borderOriginX +
              (y + borderOriginY) * sigilWithBorderDimension.w;
            sigilWithBorder[i] = symbolPixel;
            i =
              x +
              borderOriginX +
              (sigilWithBorderDimension.h - 1 - y - borderOriginY) *
                sigilWithBorderDimension.w;
            sigilWithBorder[i] = symbolPixel;
            i =
              sigilWithBorderDimension.w -
              1 -
              x -
              borderOriginX +
              (sigilWithBorderDimension.h - 1 - y - borderOriginY) *
                sigilWithBorderDimension.w;
            sigilWithBorder[i] = symbolPixel;
          }
        }
      }
    }
  }

  return {
    sigilWithBorder,
    sigilWithBorderDimension,
    sigilBorderDimensionScalar,
    sigilType,
  };
};

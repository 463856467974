import type { BlockverseProjectId } from '@pob/blockverse';
import { PROJECT_SUPPORTED_BLOCKVERSE_CHAINS } from '@pob/blockverse';
import { isTestnetChainId, type AvailableChainId } from '@protocol/chains';

export const getBlockverseChainIds = (
  project: BlockverseProjectId | undefined,
  allowTestnets: boolean,
) => {
  const availableChainIdsByProjectHit = new Map<AvailableChainId, number>();
  for (const [pid, projectChainMap] of Object.entries(
    PROJECT_SUPPORTED_BLOCKVERSE_CHAINS,
  )) {
    if (project && project !== pid) continue;
    if (!projectChainMap) continue;
    for (const chainId of Object.values(projectChainMap)) {
      if (!allowTestnets && isTestnetChainId(chainId)) {
        continue;
      }
      let hits = 0;
      if (availableChainIdsByProjectHit.has(chainId)) {
        hits = availableChainIdsByProjectHit.get(chainId)!;
      }
      availableChainIdsByProjectHit.set(chainId, hits + 1);
    }
  }
  const chainIds = Array.from(availableChainIdsByProjectHit.entries())
    .sort((a, b) => b[1] - a[1])
    .map(([chainId]) => chainId);
  return chainIds;
};

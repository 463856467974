import { QueryStateOptions } from '../types/query';

export const CONSTANT_QUERY_LIVENESS: QueryStateOptions = {
  staleTime: Infinity, // 24 hr stale times, since cache is 24 hrs, this is effectively constant
};

export const CURRENT_SESSION_LIVENESS: QueryStateOptions = {
  staleTime: 1000 * 60 * 15, // 30 minute stale times, a general approximation of a session duration
};

// export const DEFAULT_FRESH_BUT_EVERY_5_MINUTES_LIVENESS: QueryStateOptions =
//   {
//     staleTime: 1000 * 60 * 5, // 5 minute stale times
//   };

export const CURRENT_PAGE_LIVENESS: QueryStateOptions = {
  staleTime: 1000 * 60 * 2.5, // 2.5 minute stale times
};

export const PER_BLOCK_LIVENESS: QueryStateOptions = {
  staleTime: 1000 * 10,
};

export const LISTEN_PER_BLOCK_LIVENESS: QueryStateOptions = {
  staleTime: 1000 * 10,
  refetchInterval: 1000 * 10,
};

export const QUERY_LIVENESS = {
  'constant': CONSTANT_QUERY_LIVENESS,
  'current-session': CURRENT_SESSION_LIVENESS,
  'slow-block': PER_BLOCK_LIVENESS,
  'listen-slow-block': LISTEN_PER_BLOCK_LIVENESS,
  'current-page': CURRENT_PAGE_LIVENESS,
  'fresh': { staleTime: 0 },
} as const;

export type QueryLiveness = keyof typeof QUERY_LIVENESS;

export const NUM_TRIES_FOR_API = 6;

export const getShortenedAddress = (
  address: string,
  start: number = 6,
  end: number = 4,
) => {
  const shortenedAddress = `${address.slice(0, start)}...${address.slice(
    -1 * end,
  )}`;
  return shortenedAddress;
};

export const getShortenedTxn = (
  address: string,
  start: number = 8,
  end: number = 6,
) => {
  const shortenedAddress = `${address.slice(0, start)}...${address.slice(
    -1 * end,
  )}`;
  return shortenedAddress;
};

export const padHexString = (hex: string, numBytes: number) => {
  return '0x' + hex.slice(2).padStart(numBytes * 2, '0');
};

import type { BlockverseProjectId } from '@pob/blockverse';
import { useEffect } from 'react';
import type { BlockverseObjId } from '~src/shared-apps/blockverse/types';
import { useBlockverseContextStore } from '../stores';
import type { HashedBlockverseContext } from '../types';
import { type BlockverseContextId } from '../types';
import type { BlockverseContext } from '../types/context';
import { getBlockverseContextHash } from '../utils/getBlockverseContextHash';

export type AddBlockverseContextParams<E extends BlockverseProjectId> = {
  context: BlockverseContext;
  objId: BlockverseObjId<E>;
};

export const useAddBlockverseContext = <E extends BlockverseProjectId>(
  // projectId: E,
  contextParams?: AddBlockverseContextParams<E>[],
) => {
  const updateContext = useBlockverseContextStore((s) => s.updateContext);

  useEffect(() => {
    if (!contextParams) {
      return;
    }
    contextParams.forEach(({ context: protoContext, objId }) => {
      const hash = getBlockverseContextHash(protoContext);
      const context = {
        ...protoContext,
        hash,
      } as HashedBlockverseContext;
      const contextKey = [
        ...objId,
        context.type,
        hash,
      ] as const satisfies BlockverseContextId<E>;
      updateContext(contextKey, context);
    });
  }, [contextParams]);
};

import { css } from '~src/styled-system/css';
import { styled } from '~src/styled-system/jsx';
import {
  actionButtonCss,
  actionWithIconButtonCss,
  baseButtonCss,
  btnTypeVariants,
  ctaButtonCss,
  minorButtonCss,
  squareIconButtonCss,
  squareMinorButtonCss,
  stdButtonCss,
} from '../button';

export const linkArrowAnchorCss = css.raw({
  '& svg': {
    width: 'icon.sixteen',
    height: 'icon.sixteen',
    tran: 'default',
    transitionProperty: 'transform',
  },
  '_hover': {
    '& svg': {
      transform: 'translate(2px, -2px)',
    },
    'textDecoration': 'underline',
  },
});

export const noStyleAnchorCss = css.raw({
  textDecoration: 'none',
  color: 'inherit',
  fontFamily: 'inherit',
});

export const baseAnchorCss = css.raw({
  textDecoration: 'underline',
  color: 'inherit',
  fontFamily: 'inherit',
});

export const hoverUnderlineAnchorVariants = {
  underline: css.raw({
    textDecoration: 'none',
    _hover: {
      textDecoration: 'underline',
    },
  }),
  none: css.raw({}),
};

export const BaseA = styled('a', {
  base: noStyleAnchorCss,
  variants: {
    hover: hoverUnderlineAnchorVariants,
  },
  defaultVariants: {
    hover: 'none',
  },
});

export const BrandA = styled('a', {
  base: css.raw(noStyleAnchorCss, {
    color: 'brand',
  }),
  variants: {
    hover: hoverUnderlineAnchorVariants,
  },
  defaultVariants: {
    hover: 'none',
  },
});

export const A = styled('a', {
  base: baseAnchorCss,
  variants: {
    hover: hoverUnderlineAnchorVariants,
  },
  defaultVariants: {
    hover: 'none',
  },
});

export const BaseButtonA = styled('a', {
  base: baseButtonCss,
});

export const SquareIconA = styled('a', {
  base: squareIconButtonCss,
  variants: {
    btnType: btnTypeVariants,
  },
});

export const ActionA = styled('a', {
  base: actionButtonCss,
  variants: {
    btnType: btnTypeVariants,
  },
});

export const MinorA = styled('a', {
  base: minorButtonCss,
  variants: {
    btnType: btnTypeVariants,
  },
});

export const CtaA = styled('a', {
  base: ctaButtonCss,
  variants: {
    btnType: btnTypeVariants,
  },
});

export const SquareMinorA = styled('a', {
  base: squareMinorButtonCss,
  variants: {
    btnType: btnTypeVariants,
  },
});

export const ActionWithIconA = styled('a', {
  base: actionWithIconButtonCss,
  variants: {
    btnType: btnTypeVariants,
  },
});

export const StdA = styled('a', {
  base: stdButtonCss,
  variants: {
    btnType: btnTypeVariants,
  },
  defaultVariants: {
    btnType: 'default',
  },
});

export const baseNavAnchorCss = css.raw({
  letterSpacing: 'navButton',
  textDecoration: 'none',
  fontFamily: 'display',
  fontSize: 'p.display',
  fontWeight: 'display.normal',
});

export const BaseNavAnchor = styled('a', {
  base: baseNavAnchorCss,
});

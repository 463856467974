import type { ExtAccountType } from '@pob/shared';
import { useMemo } from 'react';
import { useBestWalletForPrivyUser } from '../wallet/useWalletFromPrivy';
import { useFarcasterUserFromPrivy } from './useFarcasterUserFromPrivy';
import { useUniversalUserFromPrivy } from './useUniversalUserFromPrivy';

export const useBestExtAccountTypeFromPrivy = ():
  | ExtAccountType
  | undefined => {
  const farcasterUser = useFarcasterUserFromPrivy();
  const wallet = useBestWalletForPrivyUser();
  const universalUser = useUniversalUserFromPrivy();

  return useMemo(() => {
    if (universalUser) {
      return 'universal-profile';
    }
    if (farcasterUser) {
      return 'farcaster';
    }
    if (wallet) {
      return 'wallet';
    }
    return undefined;
  }, [farcasterUser, wallet, universalUser]);
};

import {
  CHAIN_ID_TO_NETWORK_LOOKUP,
  isAvailableChainId,
  isAvailableChainNetwork,
  type AvailableChainId,
  type AvailableChainNetwork,
} from '@protocol/chains';

export const getChainNetwork = <
  R extends AvailableChainNetwork = AvailableChainNetwork,
  C extends AvailableChainId | AvailableChainNetwork =
    | AvailableChainId
    | AvailableChainNetwork,
>(
  chainIdOrNetwork: C,
) => {
  if (isAvailableChainId(chainIdOrNetwork)) {
    return CHAIN_ID_TO_NETWORK_LOOKUP[chainIdOrNetwork] as R;
  }
  if (isAvailableChainNetwork(chainIdOrNetwork)) {
    return chainIdOrNetwork as unknown as R;
  }
  throw new Error('Invalid chain id or network');
};

import { css } from '~src/styled-system/css';
import { styled } from '~src/styled-system/jsx';

export const baseSpanCss = css.raw({
  fontFamily: 'inherit',
  fontWeight: 'inherit',
});

export const BaseSpan = styled('span', {
  base: baseSpanCss,
});

export const strongCss = css.raw({
  fontWeight: '[bold]',
  color: 'inherit',
  fontFamily: 'inherit',
});

export const superStrongCss = css.raw({
  ...strongCss,
  fontWeight: 'display.superBold',
});

export const Strong = styled('strong', {
  base: strongCss,
});

export const SuperStrong = styled('strong', {
  base: superStrongCss,
});

export const BrandStrong = styled('strong', {
  base: {
    ...strongCss,
    color: 'brand',
  },
});

export const BlockverseStrong = styled('strong', {
  base: {
    ...strongCss,
    color: 'blockverse.text',
  },
});

export const BlockverseSpan = styled('span', {
  base: {
    ...baseSpanCss,
    color: 'blockverse.text',
  },
});

export const BrandSpan = styled('span', {
  base: {
    ...baseSpanCss,
    color: 'brand',
  },
});

export const Italic = styled('em', {
  base: {
    fontStyle: 'italic',
    fontFamily: 'inherit',
  },
});

export const Em = Italic;

export const WeaklyLightenSpan = styled('span', {
  base: {
    ...baseSpanCss,
    opacity: '50',
  },
});

export const LightenSpan = styled('span', {
  base: {
    ...baseSpanCss,
    opacity: '30',
  },
});

export const InheritSpan = styled('span', {
  base: {
    ...baseSpanCss,
    color: 'inherit',
  },
});

export const Code = styled('code', {
  base: {
    fontFamily: 'inherit',
    paddingY: 'quarterIncrement',
    paddingX: 'threeQuartersIncrement',
    borderRadius: 'threeQuartersIncrement',
    background: 'darken',
    color: 'gray.600',
  },
});

export const ProjectSpan = Strong;

export const HighlightSpan = styled('span', {
  base: {
    px: 'threeQuartersIncrement',
    py: 'quarterIncrement',
    borderRadius: 'halfIncrement',
    whiteSpace: 'nowrap',
    bg: 'darken',
    fontFamily: 'inherit',
  },
});

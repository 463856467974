import type { BlockverseProjectId } from '@pob/blockverse';
import { useCallback, useMemo } from 'react';
import type { BlockverseObjId } from '~src/shared-apps/blockverse/types';
import {
  deserializeBlockverseObjId,
  serializeBlockverseObjId,
} from '~src/shared-apps/blockverse/utils/serialize';
import { BLOCKVERSE_OBJECT_CONTEXTS_DEFAULTS_MAP } from '../defaults';
import { useBlockverseContextStore } from '../stores';
import type { BlockverseContextId, BlockverseContextWithId } from '../types';

export const useBlockverseContextsFromObject = <E extends BlockverseProjectId>(
  ...objId: BlockverseObjId<E>
): BlockverseContextWithId[] | null => {
  const serializedObjId = useMemo(
    () => serializeBlockverseObjId(objId),
    [objId],
  );

  const blockverseObjectContextsMap = useBlockverseContextStore(
    useCallback(
      (state) => state.blockverseObjectToContexts[serializedObjId],
      [serializedObjId],
    ),
  );
  const blockverseContextsMap = useBlockverseContextStore(
    (state) => state.contexts,
  );

  const contextsFromDefaults = useMemo(() => {
    const dc = BLOCKVERSE_OBJECT_CONTEXTS_DEFAULTS_MAP.get(serializedObjId);
    if (!dc) return [];
    return [dc];
  }, [serializedObjId]);

  return useMemo(() => {
    if (!blockverseObjectContextsMap) return null;
    const rawContexts = Object.keys(blockverseObjectContextsMap).map(
      (contextId) => {
        const id = deserializeBlockverseObjId<E, BlockverseContextId>(
          contextId,
        );
        return {
          ...blockverseContextsMap[contextId],
          id,
        } as BlockverseContextWithId;
      },
    );

    rawContexts.push(...contextsFromDefaults);

    const now = Date.now();
    return rawContexts
      .filter((context) => {
        if (!context.temporal) return true;
        return context.temporal.dateUpdated + (context.temporal.ttl ?? 0) > now;
      })
      .map((c) => ({
        ...c,
      }));
  }, [
    blockverseObjectContextsMap,
    contextsFromDefaults,
    blockverseContextsMap,
  ]);
};

import { snippetFromAndBackRandomSource } from '@hiraeth/art';
import { SupportedBlockverseChainId } from '@pob/blockverse';
import type { Evm } from '@pob/shared';
import { useMemo } from 'react';
import { DEFAULT_CHAIN } from '~src/constants/rpc';
import { getBase64PlaceholderImage } from '~src/utils/image/placeholder';
import { lighten } from '~src/utils/polish';
import { useHiraethSigil } from './useHiraethSigil';

interface HiraethCrestColorMetadata {
  fillColor: string;
  secondaryFillColor: string;
}
const HIRAETH_CREST_COLORS: HiraethCrestColorMetadata[] = [
  { fillColor: '#24A3FF', secondaryFillColor: lighten(0.25, '#24A3FF') },
  { fillColor: '#F87A53', secondaryFillColor: lighten(0.25, '#F87A53') },
  { fillColor: '#CC2B52', secondaryFillColor: lighten(0.3, '#CC2B52') },
  { fillColor: '#257180', secondaryFillColor: lighten(0.35, '#257180') },
  { fillColor: '#85754A', secondaryFillColor: lighten(0.3, '#85754A') },
  { fillColor: '#6256CA', secondaryFillColor: lighten(0.3, '#6256CA') },
  { fillColor: '#697565', secondaryFillColor: lighten(0.3, '#697565') },
  { fillColor: '#55679C', secondaryFillColor: lighten(0.3, '#55679C') },
  { fillColor: '#373A40', secondaryFillColor: lighten(0.4, '#373A40') },
  { fillColor: '#387F39', secondaryFillColor: lighten(0.35, '#387F39') },
];

export type UseHiraethCrestOptions = {
  shouldUseColor?: boolean;
};

export const useHiraethCrest = (
  address?: Evm.Address,
  { shouldUseColor = false }: UseHiraethCrestOptions = {},
) => {
  const { fillColor, secondaryFillColor } = useMemo(() => {
    if (!shouldUseColor) {
      return { fillColor: undefined, secondaryFillColor: undefined };
    }
    // index should be just the first byte of the address modulo by the length of the array
    const firstByteFromStr = address?.slice(2, 4);
    const firstByte = parseInt(firstByteFromStr ?? '0', 16); // convert hex to decimal
    const index = firstByte % HIRAETH_CREST_COLORS.length;
    return HIRAETH_CREST_COLORS[index];
  }, [shouldUseColor, address]);

  return useHiraethSigil(
    useMemo(
      () => ({
        fillColor,
        secondaryFillColor,
        chainId: DEFAULT_CHAIN.id as SupportedBlockverseChainId<'hiraeth'>,
        sigilMetadata: !!address
          ? {
              type: 'address',
              quantity: 1,
              randomSource: Number(snippetFromAndBackRandomSource(address, 2)),
            }
          : undefined,
      }),
      [address, shouldUseColor, fillColor, secondaryFillColor],
    ),
  );
};

export const useHiraethCrestUri = (
  address?: Evm.Address,
  options: UseHiraethCrestOptions = {},
) => {
  const crestSigilRes = useHiraethCrest(address, options);
  return useMemo(() => {
    if (!crestSigilRes.data) {
      return getBase64PlaceholderImage(24, 24, 'transparent');
    }
    return crestSigilRes.data.uri;
  }, [crestSigilRes]);
};

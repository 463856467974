import { AvailableChainId, bitcoin } from '@protocol/chains';
import { ReactNode } from 'react';
import {
  base,
  baseSepolia,
  lukso,
  luksoTestnet,
  mainnet,
  optimism,
  optimismSepolia,
  sepolia,
} from 'viem/chains';
import { styled } from '~src/styled-system/jsx';

const ChainSpan = styled('span', {
  base: {
    fontWeight: 'display.bold',
    fontFamily: 'inherit',
  },
});

export const SPANS_BY_CHAIN_ID = {
  [base.id]: <ChainSpan color={'base'}>{base.name}</ChainSpan>,
  [baseSepolia.id]: <ChainSpan color={'gray.400'}>Sepolia</ChainSpan>,
  [mainnet.id]: <ChainSpan color={'homestead'}>{mainnet.name}</ChainSpan>,
  [sepolia.id]: <ChainSpan color={'gray.400'}>{sepolia.name}</ChainSpan>,
  [optimism.id]: <ChainSpan color={'optimism'}>Optimism</ChainSpan>,
  [optimismSepolia.id]: (
    <ChainSpan color={'gray.400'}>Optimism Sepolia</ChainSpan>
  ),
  [bitcoin.id]: <ChainSpan color={'bitcoin'}>Bitcoin</ChainSpan>,
  [lukso.id]: <ChainSpan color={'lukso'}>LUKSO</ChainSpan>,
  [luksoTestnet.id]: <ChainSpan color={'gray.400'}>LUKSO</ChainSpan>,
} as const satisfies Record<AvailableChainId, ReactNode>;

export const SpanByChainNetwork = ({
  chainId,
}: {
  chainId: AvailableChainId;
}) => {
  return SPANS_BY_CHAIN_ID[chainId];
};

import { motion } from 'framer-motion';
import { FC } from 'react';
import { styled } from '~src/styled-system/jsx';
import { styledMotionOpts } from '../../utils/panda/motion';

export const H1 = styled(
  motion.h1,
  {
    base: {
      textStyle: 'h1.default',
    },
    variants: {
      isMobile: {
        true: {
          fontSize: {
            base: 'h1.mobile',
            xs: 'h1',
          },
        },
        false: {},
      },
    },
  },
  styledMotionOpts,
);
export const H2 = styled(
  motion.h2,
  {
    base: {
      textStyle: 'h2.default',
    },
    variants: {
      isMobile: {
        true: {
          fontSize: {
            base: 'h2.mobile',
            xs: 'h2',
          },
        },
        false: {},
      },
    },
  },
  styledMotionOpts,
);

export const H3 = styled(
  motion.h3,
  {
    base: {
      textStyle: 'h3.default',
    },
    variants: {
      isMobile: {
        true: {
          fontSize: {
            base: 'h3.mobile',
            xs: 'h3',
          },
        },
        false: {},
      },
    },
  },
  styledMotionOpts,
);

export const H4 = styled(
  motion.h4,
  {
    base: {
      textStyle: 'h4.default',
    },
    variants: {
      isMobile: {
        true: {
          fontSize: {
            base: 'h4',
            xs: 'h4',
          },
        },
        false: {},
      },
    },
  },
  styledMotionOpts,
);

export const H5 = styled(
  motion.h5,
  {
    base: {
      textStyle: 'h5.default',
    },
    variants: {
      isMobile: {
        true: {
          fontSize: {
            base: 'h5',
            xs: 'h5',
          },
        },
        false: {},
      },
    },
  },
  styledMotionOpts,
);

export const H6 = styled(
  motion.h6,
  {
    base: {
      textStyle: 'h6.default',
    },
    variants: {
      isMobile: {
        true: {
          fontSize: {
            base: 'h6',
            xs: 'h6',
          },
        },
        false: {},
      },
    },
  },
  styledMotionOpts,
);

export const P = styled(
  motion.p,
  {
    base: {
      textStyle: 'p.display',
    },
  },
  styledMotionOpts,
);

export const DisplayP = {
  Default: styled(
    motion.p,
    {
      base: {
        textStyle: 'p.display',
      },
    },
    styledMotionOpts,
  ),
  Title: styled(
    motion.p,
    {
      base: {
        textStyle: 'p.display.title',
      },
    },
    styledMotionOpts,
  ),
  Caption: styled(
    motion.p,
    {
      base: {
        textStyle: 'p.display.caption',
      },
    },
    styledMotionOpts,
  ),
  Multi: styled(
    motion.p,
    {
      base: {
        textStyle: 'p.display.multi',
      },
    },
    styledMotionOpts,
  ),
  MultiCompact: styled(
    motion.p,
    {
      base: {
        textStyle: 'p.display.multiCompact',
      },
    },
    styledMotionOpts,
  ),
  Large: styled(
    motion.p,
    {
      base: {
        textStyle: 'p.display.large',
      },
    },
    styledMotionOpts,
  ),
};

export const MonoP = {
  Default: styled(
    motion.p,
    {
      base: {
        textStyle: 'p.mono',
      },
    },
    styledMotionOpts,
  ),
  Multi: styled(
    motion.p,
    {
      base: {
        textStyle: 'p.mono.multi',
      },
    },
    styledMotionOpts,
  ),
};

export const NewParagraph: FC = () => {
  return (
    <>
      <br />
      <br />
    </>
  );
};

import { css } from '~src/styled-system/css';

import { Popover as ArkPopover, PopoverRootProps } from '@ark-ui/react';
import { forwardClassName } from '~src/utils/panda/forwardClassName';
// export const PopoverMenu = forwardedStaticElement('div')(popoverContent);

export const popoverContentCss = css.raw({
  zIndex: 'popover',
  border: '1px solid',
  borderColor: 'gray.200',
  background: 'gray.100',
  borderRadius: 'dropdown',
  boxShadow: 'default',
  overflow: 'hidden',
  _open: {
    animation: '[fadeIn 0.25s ease-out]',
  },
  _closed: {
    animation: '[fadeOut 0.2s ease-out]',
  },
  _hidden: {
    display: 'none',
  },
});

export const popoverPositionerCss = css.raw({
  position: 'relative',
});

/**
 * Popover component
 *  - Root
 *    - Trigger
 *    - Portal
 *      - Positioner
 *        - Content
 *          - DropdownItemGroupGrid
 *           - DropdownItemGroup + DeepDropdownItemGroup
 *             - DropdownLabel
 *             - DropdownItemA + DropdownItemButton
 *             - DropdownLineSeparator
 */
export const Popover = {
  ...ArkPopover,
  Positioner: forwardClassName(ArkPopover.Positioner)(
    css(popoverPositionerCss),
  ),
  Content: forwardClassName(ArkPopover.Content)(css(popoverContentCss)),
};

export type PopoverPositioning = NonNullable<PopoverRootProps['positioning']>;

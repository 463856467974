import type { Evm } from '@pob/shared';
import type { Transaction, TransactionReceipt } from 'viem';
import { getScriptureScoreWeights } from '../art/composition/region';
import {
  ArtGene,
  ArtGeneAdditionalMetadata,
  ProtoSigilMetadata,
  type ArtGeneAdditionalMetadataV1,
  type ArtGeneAdditionalMetadataV2,
} from '../art/types';
const clamp = (value: number, min: number, max: number): number => {
  return Math.min(max, Math.max(min, value));
};

export interface MapEvmOptionsV1 {
  maxGasPerTxn: number;
  lightingRandomSourceStepSize: number;
  corpusMaxSizeInBytes: number;
  degradationBaseInDays: number;
  degradationBase: number;
  transferEventSignatures: string[];
  txnTypeRandomSourceBase: number;
  version: '1.0.0';
}

export interface MapEvmOptionsV2 extends Omit<MapEvmOptionsV1, 'version'> {
  version: '2.0.0';
  eventEmitterCrestThreshold: number;
  maxEventEmitterCrests: number;
  eventEmitterColorOverrideThreshold: number;
}

export type MultiVersionMapEvmOptions = MapEvmOptionsV1 | MapEvmOptionsV2;

export type CurrentChainState = {
  blockTimestamp: number;
  chainId: number;
};

export type MaterialState = {
  randomSource?: number;
  encodedColorString?: string;
};

export type TransactionExtraState = {
  timestamp: number;
};

export const DEFAULT_MAP_EVM_OPTIONS: MapEvmOptionsV1 = {
  maxGasPerTxn: 5000000, // about as much to deploy a max size contract
  lightingRandomSourceStepSize: 200,
  corpusMaxSizeInBytes: 50000, // max contract size
  degradationBaseInDays: 31,
  degradationBase: 7,
  transferEventSignatures: [
    '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef',
  ],
  txnTypeRandomSourceBase: 3,
  version: '1.0.0',
};

export const DEGRADATION_THRESHOLD_STEP = 2;

export const mapEvmTxnDataToArtGeneFactory = (
  options: MultiVersionMapEvmOptions,
) => {
  return (
    transaction: Transaction,
    receipt: TransactionReceipt,
    extraState: TransactionExtraState,
    chainState: CurrentChainState,
    materialState: MaterialState,
  ): { artGene: ArtGene; additionalMetadata: ArtGeneAdditionalMetadata } => {
    // console.log(`Mapping transaction data to art gene for ${transaction.hash}`);
    const offset = Number((transaction.blockNumber ?? 0n) % 32n);
    // console.log(`Transaction offset: ${offset}`);

    const corpus: Uint8Array = new Uint8Array(
      Math.min((transaction.input.length - 2) * 2, 1000),
    );
    for (let i = 0; i < corpus.length; i++) {
      const hex = transaction.input.charAt(i + 2);
      corpus[i] = parseInt(hex, 16);
    }

    let daysElapsed =
      (chainState.blockTimestamp - extraState.timestamp) / 86400;
    let degradationDayThreshold = options.degradationBaseInDays;
    let degradationDelta = 0;
    while (daysElapsed > degradationDayThreshold) {
      degradationDelta++;
      degradationDayThreshold *= DEGRADATION_THRESHOLD_STEP;
    }
    degradationDayThreshold /= DEGRADATION_THRESHOLD_STEP;

    const sigilMetadataMap = new Map<string, ProtoSigilMetadata>();

    let emittersEntries: [Evm.Address, number][] = [];

    const emitterLogKeys: Evm.Address[] = [];

    if (options.version === '2.0.0') {
      console.log(`Version 2.0: mapping event emitter crests`);
      // create a Map of all the hits of a event log emitter
      const eventEmitterHits = new Map<Evm.Address, number>();
      for (let i = 0; i < receipt.logs.length; i++) {
        const log = receipt.logs[i];
        const emitter = log.address as Evm.Address;
        const hitCount = eventEmitterHits.get(emitter) ?? 0;
        eventEmitterHits.set(emitter, hitCount + 1);
      }
      // sort and filter the event emitter entries, only keep the top maxEventEmitterCrests
      const sortedEventEmitterHits = Array.from(eventEmitterHits.entries())
        .sort((a, b) => b[1] - a[1])
        .filter(
          ([_, hitCount]) => hitCount > options.eventEmitterCrestThreshold,
        );

      const filteredEventEmitterHits = sortedEventEmitterHits.slice(
        0,
        options.maxEventEmitterCrests,
      );
      for (const [emitter, hitCount] of filteredEventEmitterHits) {
        // console.log(`Emitter: ${emitter} ${hitCount}`);
        // if emitter is either the from or to address, then we don't want to count it
        if (
          emitter.toLowerCase() === receipt.from.toLowerCase() ||
          emitter.toLowerCase() ===
            (receipt.contractAddress?.toLowerCase() ??
              receipt.to?.toLowerCase())
        ) {
          continue;
        }
        console.log(
          'emitter',
          emitter,
          hitCount,
          snippetFromAndBackRandomSource(emitter, 2),
        );
        sigilMetadataMap.set(emitter, {
          type: 'address',
          quantity:
            Math.floor((hitCount - options.maxEventEmitterCrests) / 2) + 1,
          randomSource: Number(snippetFromAndBackRandomSource(emitter, 2)),
        });
        emitterLogKeys.push(emitter);
      }
      emittersEntries = Array.from(eventEmitterHits.entries());
    }

    for (let i = 0; i < receipt.logs.length; i++) {
      const log = receipt.logs[i];
      const isTransfer = options.transferEventSignatures.includes(
        log.topics[0]!.toLowerCase(),
      );
      let logKey = '';
      if (isTransfer) {
        logKey = log.topics[0]! + log.address;
        if (!sigilMetadataMap.has(logKey)) {
          sigilMetadataMap.set(logKey, {
            quantity: 0,
            type: 'transfer',
            randomSource: Number(snippetRandomSource(log.address, 0, 8)),
            fromRandomSource: Number(snippetRandomSource(log.address, 8, 8)),
            toRandomSource: Number(snippetRandomSource(log.address, 12, 8)),
          });
        }
      } else {
        logKey = log.topics[0]!;
        if (!sigilMetadataMap.has(logKey)) {
          sigilMetadataMap.set(logKey, {
            quantity: 0,
            type: 'default',
            randomSource: Number(snippetRandomSource(logKey, 0, 8)),
          });
        }
      }
      sigilMetadataMap.get(logKey)!.quantity++;
    }

    const sigilMetadataEntries = Array.from(sigilMetadataMap.entries());

    const fromRandomSource = Number(
      snippetFromAndBackRandomSource(receipt.from, 2),
    );

    const destinationRandomSource = Number(
      snippetFromAndBackRandomSource(receipt.contractAddress ?? receipt.to!, 2),
    );

    console.log('destinationRandomSource', destinationRandomSource);

    let emitterColorOverrideDestinationRandomSource: number | null = null;

    let colorSource: Evm.Address | null =
      (receipt.contractAddress ?? receipt.to ?? null) as Evm.Address | null;

    if (options.version === '2.0.0') {
      console.log(`Version 2.0: remapping colors`);
      const emitterEntriesForColors = emittersEntries.filter(
        ([_, hitCount]) =>
          hitCount > options.eventEmitterColorOverrideThreshold,
      );
      if (emitterEntriesForColors.length > 0) {
        colorSource = emitterEntriesForColors[0][0];
        emitterColorOverrideDestinationRandomSource = Number(
          snippetFromAndBackRandomSource(colorSource, 2),
        );
        console.log(
          `Destination random source derived from: ${colorSource}`,
        );
      }
    }

    const materialRandomSource =
      materialState.randomSource ??
      chainState.chainId +
        (emitterColorOverrideDestinationRandomSource ??
          destinationRandomSource) /
          2;

    const artGene = {
      materialRandomSource,
      txnRandomSource: Number(
        snippetRandomSource(receipt.transactionHash, offset, 8),
      ),
      encodedColorString: materialState.encodedColorString,
      fromRandomSource,
      destinationRandomSource,
      txnTypeRandomSource:
        Number(transaction.typeHex ?? 0) + options.txnTypeRandomSourceBase,
      sigils: sigilMetadataEntries.map(([key, value]) => value),
      complexityScore: clamp(
        (Number(receipt.gasUsed) - 21000) / options.maxGasPerTxn,
        0,
        1,
      ),
      lightingRandomSource: Math.floor(
        transaction.nonce / options.lightingRandomSourceStepSize,
      ),
      degradation: degradationDelta + options.degradationBase,
      corpus,
      corpusScore: clamp(
        transaction.input.length / options.corpusMaxSizeInBytes,
        0,
        1,
      ),
    };

    let additionalMetadata: ArtGeneAdditionalMetadata = {
      daysElapsed,
      degradationThreshold: degradationDayThreshold,
      nextDegradationThreshold:
        degradationDayThreshold * DEGRADATION_THRESHOLD_STEP,
      currentRenderingState: chainState,
      sigilLogKeys: sigilMetadataEntries.map(([key, _]) => key),
      regionMarkerWeights: getScriptureScoreWeights(
        artGene.lightingRandomSource,
      ),
      relativeDegradation: degradationDelta,
      version: '1.0.0',
      colorSource,
    } satisfies ArtGeneAdditionalMetadataV1;

    if (options.version === '2.0.0') {
      additionalMetadata = {
        ...additionalMetadata,
        emitterLogKeys,
        numEventsPerEventEmitter: Object.fromEntries(emittersEntries),
        version: '2.0.0',
      } satisfies ArtGeneAdditionalMetadataV2;
    }

    // console.log(
    //   `Transaction gas: ${receipt.gasUsed} ${artGene.complexityScore}`,
    // );
    // console.log(
    //   `Transaction input length: ${transaction.input.length} ${artGene.corpusScore}`,
    // );
    return {
      artGene,
      additionalMetadata,
    };
  };
};

const snippetRandomSource = (
  hexStr: string,
  offset: number,
  sizeInBytes: number,
): number => {
  return parseInt(hexStr.slice(2 + offset, offset + 2 * sizeInBytes), 16);
};

export const snippetFromAndBackRandomSource = (
  hexStr: string,
  sizeInBytes: number,
): number => {
  return parseInt(
    hexStr.slice(2, 2 + 2 * sizeInBytes) +
      hexStr.slice(hexStr.length - 2 * sizeInBytes),
    16,
  );
};

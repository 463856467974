import { getPatternStyles, patternFns } from '../helpers.mjs';
import { css } from '../css/index.mjs';

const contentSpacerConfig = {
transform(props) {
  const { size, isMobile } = props;
  if (isMobile) {
    return {
      height: {
        base: `calc({sizes.${size}} * 0.5)`,
        xs: size
      }
    };
  }
  return {
    height: size
  };
},
defaultValues:{isMobile:false,size:'largestBox'}}

export const getContentSpacerStyle = (styles = {}) => {
  const _styles = getPatternStyles(contentSpacerConfig, styles)
  return contentSpacerConfig.transform(_styles, patternFns)
}

export const contentSpacer = (styles) => css(getContentSpacerStyle(styles))
contentSpacer.raw = getContentSpacerStyle
const DEGRADATION_TO_LABEL = {
  0: 'Rosetta',
  1: 'Hammurabi',
  2: 'Alexandria',
  3: 'Behistun',
  4: 'Nazca',
  5: 'Svalbard',
  6: 'Yucca',
  7: 'ANSI Z535',
} as Record<number, string>;

const DEFAULT_DEGRADATION = (degradation: number) => `Voyager ${degradation}`;

export const getDegradationLabelFromRelativeDegradation = (
  relativeDegradation: number,
) => {
  if (!DEGRADATION_TO_LABEL[relativeDegradation]) {
    return DEFAULT_DEGRADATION(relativeDegradation);
  }
  return DEGRADATION_TO_LABEL[relativeDegradation];
};

import { AvailableChainId } from '@protocol/chains';
import { ProjectId } from '@protocol/deployments';
import { useMemo } from 'react';

export const useSupportedByMapChainIdFactory =
  <TProjectId extends ProjectId, TChainId extends AvailableChainId>(
    map: Record<
      TProjectId,
      | {
          [chainId in AvailableChainId]?: TChainId;
        }
      | null
    >,
  ) =>
  (
    projectId?: TProjectId,
    chainId?: AvailableChainId,
  ): TChainId | undefined => {
    return useMemo(() => {
      if (!projectId) return undefined;
      if (!chainId) return undefined;
      return map[projectId]?.[chainId] ?? undefined;
    }, [projectId, chainId, map]);
  };

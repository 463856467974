import { OverlappingWFCModel } from '../algorithms/model/wfc';
import {
  WALL_PIXEL,
  WILD_CARD_PIXEL,
} from '../algorithms/variant/simple-walls';
import {
  ArtState,
  CompositionSymmetry,
  Cord,
  Dimension,
  Rect,
  SigilMetadata,
  SigilMetadataBase,
} from '../types';
import { getBoundingDimensions } from '../utils/2d';
import {
  REGION_BORDER_DIMENSION_SCALAR,
  SIGIL_ADDITION_BORDER_DIMENSION_SCALAR,
  SIGIL_DRAWABLE_DIMENSIONS,
} from './constants';

const NUM_TRIES_TO_SPAWN_SIGIL = 40;

const SIGIL_BOUND_BUFFER = 4;

const EMPTY_WALL_TILE: number[] = [
  WILD_CARD_PIXEL,
  WILD_CARD_PIXEL,
  WILD_CARD_PIXEL,
  WILD_CARD_PIXEL,
  WILD_CARD_PIXEL,
  WILD_CARD_PIXEL,
  WILD_CARD_PIXEL,
  WILD_CARD_PIXEL,
  WILD_CARD_PIXEL,
];

const LEFT_WALL_TILE: number[] = [
  WALL_PIXEL,
  WILD_CARD_PIXEL,
  WILD_CARD_PIXEL,
  WALL_PIXEL,
  WILD_CARD_PIXEL,
  WILD_CARD_PIXEL,
  WALL_PIXEL,
  WILD_CARD_PIXEL,
  WILD_CARD_PIXEL,
];
const RIGHT_WALL_TILE: number[] = [
  WILD_CARD_PIXEL,
  WILD_CARD_PIXEL,
  WALL_PIXEL,
  WILD_CARD_PIXEL,
  WILD_CARD_PIXEL,
  WALL_PIXEL,
  WILD_CARD_PIXEL,
  WILD_CARD_PIXEL,
  WALL_PIXEL,
];
const TOP_WALL_TILE: number[] = [
  WALL_PIXEL,
  WALL_PIXEL,
  WALL_PIXEL,
  WILD_CARD_PIXEL,
  WILD_CARD_PIXEL,
  WILD_CARD_PIXEL,
  WILD_CARD_PIXEL,
  WILD_CARD_PIXEL,
  WILD_CARD_PIXEL,
];
const BOTTOM_WALL_TILE: number[] = [
  WILD_CARD_PIXEL,
  WILD_CARD_PIXEL,
  WILD_CARD_PIXEL,
  WILD_CARD_PIXEL,
  WILD_CARD_PIXEL,
  WILD_CARD_PIXEL,
  WALL_PIXEL,
  WALL_PIXEL,
  WALL_PIXEL,
];

enum SigilSpawn {
  Quadrant = 0,
  YAxis = 1,
  XAxis = 2,
  Center = 3,
}

const SIGIL_SPAWN_WEIGHTS_BY_SYMMETRY = {
  [CompositionSymmetry.Quadrant]: {
    4: {
      [SigilSpawn.Quadrant]: 0.85,
      [SigilSpawn.YAxis]: 0.05,
      [SigilSpawn.XAxis]: 0.05,
      [SigilSpawn.Center]: 0.05,
    },
    3: {
      [SigilSpawn.Quadrant]: 0.55,
      [SigilSpawn.YAxis]: 0.2,
      [SigilSpawn.XAxis]: 0.2,
      [SigilSpawn.Center]: 0.05,
    },
    2: {
      [SigilSpawn.Quadrant]: 0,
      [SigilSpawn.YAxis]: 0.25,
      [SigilSpawn.XAxis]: 0.75,
      [SigilSpawn.Center]: 0,
    },
    1: {
      [SigilSpawn.Quadrant]: 0,
      [SigilSpawn.YAxis]: 0.8,
      [SigilSpawn.XAxis]: 0,
      [SigilSpawn.Center]: 0.2,
    },
  },
  [CompositionSymmetry.Vertical]: {
    4: {
      [SigilSpawn.Quadrant]: 0.5,
      [SigilSpawn.YAxis]: 0.5,
      [SigilSpawn.XAxis]: 0.0,
      [SigilSpawn.Center]: 0.0,
    },
    3: {
      [SigilSpawn.Quadrant]: 0.5,
      [SigilSpawn.YAxis]: 0.5,
      [SigilSpawn.XAxis]: 0.0,
      [SigilSpawn.Center]: 0.0,
    },
    2: {
      [SigilSpawn.Quadrant]: 0.5,
      [SigilSpawn.YAxis]: 0.5,
      [SigilSpawn.XAxis]: 0,
      [SigilSpawn.Center]: 0,
    },
    1: {
      [SigilSpawn.Quadrant]: 0.2,
      [SigilSpawn.YAxis]: 0.8,
      [SigilSpawn.XAxis]: 0,
      [SigilSpawn.Center]: 0,
    },
  },
  [CompositionSymmetry.Horizontal]: {
    4: {
      [SigilSpawn.Quadrant]: 0.5,
      [SigilSpawn.XAxis]: 0.5,
      [SigilSpawn.YAxis]: 0.0,
      [SigilSpawn.Center]: 0.0,
    },
    3: {
      [SigilSpawn.Quadrant]: 0.5,
      [SigilSpawn.XAxis]: 0.5,
      [SigilSpawn.YAxis]: 0.0,
      [SigilSpawn.Center]: 0.0,
    },
    2: {
      [SigilSpawn.Quadrant]: 0.5,
      [SigilSpawn.XAxis]: 0.5,
      [SigilSpawn.YAxis]: 0,
      [SigilSpawn.Center]: 0,
    },
    1: {
      [SigilSpawn.Quadrant]: 0.2,
      [SigilSpawn.XAxis]: 0.8,
      [SigilSpawn.YAxis]: 0,
      [SigilSpawn.Center]: 0,
    },
  },
} as Record<CompositionSymmetry, Record<number, Record<SigilSpawn, number>>>;

const SIGIL_SPAWN_NUM_IN_COMPOSITION_BY_SYMMETRY = {
  [CompositionSymmetry.Quadrant]: {
    [SigilSpawn.Quadrant]: 4,
    [SigilSpawn.YAxis]: 2,
    [SigilSpawn.XAxis]: 2,
    [SigilSpawn.Center]: 1,
  },
  [CompositionSymmetry.Vertical]: {
    [SigilSpawn.Quadrant]: 2,
    [SigilSpawn.YAxis]: 1,
    [SigilSpawn.XAxis]: 0,
    [SigilSpawn.Center]: 0,
  },
  [CompositionSymmetry.Horizontal]: {
    [SigilSpawn.Quadrant]: 2,
    [SigilSpawn.XAxis]: 1,
    [SigilSpawn.YAxis]: 0,
    [SigilSpawn.Center]: 0,
  },
} as const satisfies Record<CompositionSymmetry, Record<SigilSpawn, number>>;

export const getSigilInitCollapseStates = (
  artState: ArtState,
  model: OverlappingWFCModel,
  runtimeDimension: Dimension,
  tries: number,
): [number, number][] => {
  const sigilBoundBuffer = SIGIL_BOUND_BUFFER + Math.floor(tries * 0.5);
  const { random, weightedRandom } = artState.txn.random;
  const compositionSymmetry = artState.composition.symmetry;
  const getInitCollapseStatesForRect = getInitCollapseStatesForRectFactory(
    model,
    runtimeDimension,
  );
  const initCollapseStates: [number, number][] = [];
  const sigilBounds: Rect[] = [];
  const numSigilsByTypeAndQuantity = new Map<
    `${SigilMetadata['type']}-${number}`,
    number
  >();
  const numSigilsInCompositionByTypeAndQuantity = new Map<
    `${SigilMetadata['type']}-${number}`,
    number
  >();
  for (let i = 0; i < artState.txn.sigils.length; i++) {
    const sigil = artState.txn.sigils[i];
    const key =
      `${sigil.type}-${sigil.quantity}` as `${SigilMetadata['type']}-${number}`;
    numSigilsByTypeAndQuantity.set(
      key,
      (numSigilsByTypeAndQuantity.get(key) || 0) + 1,
    );
    numSigilsInCompositionByTypeAndQuantity.set(key, 0);
  }

  const sigilByTypeAndQuantityKeys = Array.from(
    numSigilsByTypeAndQuantity.keys(),
  );
  for (let i = 0; i < sigilByTypeAndQuantityKeys.length; i++) {
    const key = sigilByTypeAndQuantityKeys[i];
    const splittedKey = key.split('-');
    let tries = 0;
    // console.log('key', key)
    while (
      numSigilsInCompositionByTypeAndQuantity.get(key)! <
        numSigilsByTypeAndQuantity.get(key)! &&
      tries < NUM_TRIES_TO_SPAWN_SIGIL
    ) {
      const sigilDimension: Dimension = getSigilDimension({
        type: splittedKey[0] as SigilMetadata['type'],
        quantity: parseInt(splittedKey[1]),
      });
      const numSigilsInComposition =
        numSigilsInCompositionByTypeAndQuantity.get(key)!;
      const numSigilsLeft =
        numSigilsByTypeAndQuantity.get(key)! - numSigilsInComposition;
      // console.log('numSigilsLeft', numSigilsLeft, tries)
      const spawnWeights =
        SIGIL_SPAWN_WEIGHTS_BY_SYMMETRY[compositionSymmetry][
          Math.min(numSigilsLeft, 4)
        ];
      const isEvenWidth = sigilDimension.w % 2 === 0;
      const isEvenHeight = sigilDimension.h % 2 === 0;
      if (!isEvenWidth) {
        spawnWeights[SigilSpawn.YAxis] = 0;
      }
      if (!isEvenHeight) {
        spawnWeights[SigilSpawn.XAxis] = 0;
      }
      if (!isEvenWidth && !isEvenHeight) {
        spawnWeights[SigilSpawn.Center] = 0;
      }
      const totalWeight = Object.values(spawnWeights).reduce(
        (acc, weight) => acc + weight,
        0,
      );
      for (const spawn in spawnWeights) {
        spawnWeights[spawn as any as SigilSpawn] /= totalWeight;
      }
      const spawn: SigilSpawn = weightedRandom(spawnWeights)!;
      let topLeft: Cord = { x: 0, y: 0 };
      if (spawn === SigilSpawn.Quadrant) {
        topLeft = {
          x: Math.floor(
            2 + random() * (runtimeDimension.w - sigilDimension.w - 8),
          ),
          y: Math.floor(
            2 + random() * (runtimeDimension.h - sigilDimension.h - 8),
          ),
        };
      } else if (spawn === SigilSpawn.YAxis) {
        topLeft = {
          x: runtimeDimension.w - Math.floor(sigilDimension.w / 2) - 1,
          y: Math.floor(
            2 + random() * (runtimeDimension.h - sigilDimension.h - 8),
          ),
        };
      } else if (spawn === SigilSpawn.XAxis) {
        topLeft = {
          x: Math.floor(
            2 + random() * (runtimeDimension.w - sigilDimension.w - 8),
          ),
          y: runtimeDimension.h - Math.floor(sigilDimension.h / 2) - 1,
        };
      } else if (spawn === SigilSpawn.Center) {
        topLeft = {
          x: runtimeDimension.w - Math.floor(sigilDimension.w / 2) - 1,
          y: runtimeDimension.h - Math.floor(sigilDimension.h / 2) - 1,
        };
      }

      const rect: Rect = {
        topLeft,
        bottomRight: {
          x: topLeft.x + sigilDimension.w,
          y: topLeft.y + sigilDimension.h,
        },
      };
      const isOverlapping = sigilBounds.some((sigilBound) => {
        const bufferedSigilBound: Rect = {
          topLeft: {
            x: sigilBound.topLeft.x - sigilBoundBuffer,
            y: sigilBound.topLeft.y - sigilBoundBuffer,
          },
          bottomRight: {
            x: sigilBound.bottomRight.x + sigilBoundBuffer,
            y: sigilBound.bottomRight.y + sigilBoundBuffer,
          },
        };

        return (
          rect.topLeft.x < bufferedSigilBound.bottomRight.x &&
          rect.bottomRight.x > bufferedSigilBound.topLeft.x &&
          rect.topLeft.y < bufferedSigilBound.bottomRight.y &&
          rect.bottomRight.y > bufferedSigilBound.topLeft.y
        );
      });
      // console.log(key, tries, 'rect', rect.topLeft, sigilDimension, spawn, spawnWeights, isOverlapping,
      // sigilBounds.length)
      if (isOverlapping) {
        tries++;
        continue;
      }
      tries = 0;
      sigilBounds.push(rect);
      const sigilInitCollapseStates = getInitCollapseStatesForRect(rect);
      initCollapseStates.push(...sigilInitCollapseStates);
      numSigilsInCompositionByTypeAndQuantity.set(
        key,
        numSigilsInCompositionByTypeAndQuantity.get(key)! +
          SIGIL_SPAWN_NUM_IN_COMPOSITION_BY_SYMMETRY[compositionSymmetry][
            spawn
          ],
      );
    }
  }

  return initCollapseStates;
};

const getInitCollapseStatesForRectFactory = (
  model: OverlappingWFCModel,
  runtimeDimension: Dimension,
) => {
  const tileAndIndexes = model.tileAndHits.map(
    ([tile], index) => [tile, index] as [Uint8Array, number],
  );

  let leftWallIndex = -1;
  let rightWallIndex = -1;
  let topWallIndex = -1;
  let bottomWallIndex = -1;
  let emptyWallIndex = -1;
  for (let i = 0; i < tileAndIndexes.length; i++) {
    const [tile, index] = tileAndIndexes[i];
    if (
      leftWallIndex === -1 &&
      tile.every((metaPixel, i) => metaPixel === LEFT_WALL_TILE[i])
    ) {
      leftWallIndex = index;
    }
    if (
      rightWallIndex === -1 &&
      tile.every((metaPixel, i) => metaPixel === RIGHT_WALL_TILE[i])
    ) {
      rightWallIndex = index;
    }
    if (
      topWallIndex === -1 &&
      tile.every((metaPixel, i) => metaPixel === TOP_WALL_TILE[i])
    ) {
      topWallIndex = index;
    }
    if (
      bottomWallIndex === -1 &&
      tile.every((metaPixel, i) => metaPixel === BOTTOM_WALL_TILE[i])
    ) {
      bottomWallIndex = index;
    }
    if (
      emptyWallIndex === -1 &&
      tile.every((metaPixel, i) => metaPixel === EMPTY_WALL_TILE[i])
    ) {
      emptyWallIndex = index;
    }
  }

  if (
    leftWallIndex === -1 ||
    rightWallIndex === -1 ||
    topWallIndex === -1 ||
    bottomWallIndex === -1
  ) {
    throw new Error('Could not find wall tiles');
  }

  return (rect: Rect): [number, number][] => {
    const initCollapseStates: [number, number][] = [];
    const dimension = getBoundingDimensions(rect);

    for (let dy = 2; dy < dimension.h - 2; dy++) {
      for (let dx = 2; dx < dimension.w - 2; dx++) {
        let x = rect.topLeft.x + dx;
        const y = rect.topLeft.y + dy;
        if (
          x < 0 ||
          y < 0 ||
          x >= runtimeDimension.w ||
          y >= runtimeDimension.h
        ) {
          continue;
        }
        initCollapseStates.push([x + y * runtimeDimension.w, emptyWallIndex]);
      }
    }

    for (let dy = 1; dy < dimension.h - 1; dy++) {
      let x = rect.topLeft.x;
      const y = rect.topLeft.y + dy;
      if (
        x < 0 ||
        y < 0 ||
        x >= runtimeDimension.w ||
        y >= runtimeDimension.h
      ) {
        continue;
      }
      initCollapseStates.push([x + y * runtimeDimension.w, leftWallIndex]);
      x = rect.bottomRight.x - 1;
      if (
        x < 0 ||
        y < 0 ||
        x >= runtimeDimension.w ||
        y >= runtimeDimension.h
      ) {
        continue;
      }
      initCollapseStates.push([x + y * runtimeDimension.w, rightWallIndex]);
    }
    for (let dx = 1; dx < dimension.w - 1; dx++) {
      const x = rect.topLeft.x + dx;
      let y = rect.topLeft.y;
      if (
        x < 0 ||
        y < 0 ||
        x >= runtimeDimension.w ||
        y >= runtimeDimension.h
      ) {
        continue;
      }
      initCollapseStates.push([x + y * runtimeDimension.w, topWallIndex]);
      y = rect.bottomRight.y - 1;
      if (
        x < 0 ||
        y < 0 ||
        x >= runtimeDimension.w ||
        y >= runtimeDimension.h
      ) {
        continue;
      }
      initCollapseStates.push([x + y * runtimeDimension.w, bottomWallIndex]);
    }
    return initCollapseStates;
  };
};

export const getSigilBorderDimensionScalar = (
  protoSigilMetadata: SigilMetadataBase,
): number => {
  return (
    (protoSigilMetadata.quantity - 1).toString(2).length *
    SIGIL_ADDITION_BORDER_DIMENSION_SCALAR
  );
};

export const getSigilDimension = (
  protoSigilMetadata: SigilMetadataBase,
): Dimension => {
  const type = protoSigilMetadata.type as SigilMetadata['type']; // TODO: hack
  const borderDimension = getSigilBorderDimensionScalar(protoSigilMetadata);
  return {
    w:
      SIGIL_DRAWABLE_DIMENSIONS[type].w +
      borderDimension * 2 -
      REGION_BORDER_DIMENSION_SCALAR * 2,
    h:
      SIGIL_DRAWABLE_DIMENSIONS[type].h +
      borderDimension * 2 -
      REGION_BORDER_DIMENSION_SCALAR * 2,
  };
};

import { type BlockverseProjectId } from '@pob/blockverse';
import { AvailableChainId } from '@protocol/chains';
import { useMemo } from 'react';
import { getBlockverseChainIds } from '../utils/getBlockverseChainIds';

export const useBlockverseChainIds = (
  project?: BlockverseProjectId,
  allowTestnets: boolean = false,
) => {
  const searchChainIds: AvailableChainId[] = useMemo(() => {
    return getBlockverseChainIds(project, allowTestnets);
  }, [allowTestnets]);
  return searchChainIds;
};

import { ProtoSigilMetadata } from '@hiraeth/art';
import { SupportedBlockverseChainId } from '@pob/blockverse';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { colors } from '~src/theme/tokens/colors';
import { useHiraethWorkers } from '../providers/HiraethWorkers';

export type HiraethSigilQueryParams = {
  quantity?: number;
  randomSource?: number;
  toRandomSource?: number;
  fromRandomSource?: number;
  fillColor?: string;
};

export type HiraethSigilOptions = {
  chainId?: SupportedBlockverseChainId<'hiraeth'>;
  fillColor?: string;
  secondaryFillColor?: string;
  sigilMetadata?: ProtoSigilMetadata;
};

export const useHiraethSigil = (options: HiraethSigilOptions) => {
  const { sigilComlinkRef } = useHiraethWorkers();

  const queryFn = async () => {
    if (!options.chainId) {
      return undefined;
    }
    if (!options.sigilMetadata) {
      return undefined;
    }
    if (!sigilComlinkRef.current) {
      throw new Error('Sigil worker not initialized');
    }
    const result = await sigilComlinkRef.current?.getSvgForSigilMetadata(
      options.chainId,
      options.sigilMetadata,
      options.fillColor ?? colors['gray.600'],
      options.secondaryFillColor ?? colors['gray.300'],
    );
    return result;
  };

  const queryKey = useMemo(() => {
    return [
      'sigil',
      options.chainId,
      options.sigilMetadata,
      options.fillColor,
      options.secondaryFillColor,
    ];
  }, [options]);

  return useQuery({
    queryKey,
    queryFn,
    enabled: !!options.chainId && !!options.sigilMetadata,
    retry: (failureCount, error) => {
      if (
        error instanceof Error &&
        error.message === 'Sigil worker not initialized'
      ) {
        return failureCount < 3;
      }
      return false;
    },
  });
};

import { COMPOSITION_QUADRANT_DIMENSION_IN_UNITS } from './art/composition/constants';
import { COMPOSITION_TO_PIXELS } from './art/pixels/constants';
import { Dimension } from './art/types';
import { optimizedHarrisonWasmCodeBase64 } from './wasm/optimizedHarrison';
import { optimizedPostProcessWasmCodeBase64 } from './wasm/optimizedPostProcess';
import { optimizedWfcWasmCodeBase64 } from './wasm/optimizedWfc';

export * from './art';
export * from './art/metadata';
export * from './art/metadata/labels';
export * from './art/pixels/imageData';
export * from './art/pixels/svg';
export * from './art/types';
export * from './mapper';
export * from './parameters';
export * from './parameters/types';

export { getSigilDimension } from './art/composition/sigil';

export const harrisonWasmCodeBase64 = optimizedHarrisonWasmCodeBase64;

export const wfcWasmCodeBase64 = optimizedWfcWasmCodeBase64;

export const postProcessWasmCodeBase64 = optimizedPostProcessWasmCodeBase64;

export const HIRAETH_ART_RATIO =
  COMPOSITION_QUADRANT_DIMENSION_IN_UNITS.w /
  COMPOSITION_QUADRANT_DIMENSION_IN_UNITS.h;

export const HIRAETH_PIXEL_DIMENSION: Dimension = {
  w: COMPOSITION_QUADRANT_DIMENSION_IN_UNITS.w * 2 * COMPOSITION_TO_PIXELS,
  h: COMPOSITION_QUADRANT_DIMENSION_IN_UNITS.h * 2 * COMPOSITION_TO_PIXELS,
};

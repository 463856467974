import { getRandomFactory } from '@pob/art-utils';
import type { Evm } from '@pob/shared';
import { Transaction, TransactionReceipt } from 'viem';
import { TransactionExtraState } from '../../mapper';
import { ColorPalette } from '../colors/types';

export type ArtState = {
  debug: boolean;
  resolution: number;
  colorPalette: ColorPalette;
  composition: {
    symmetry: CompositionSymmetry;
    numContinents: number;
    degradation: number;
    complexityScore: number;
  };
  language: {
    characterWidth: number;
    characterHeight: number;
    corpus: Uint8Array;
    corpusScore: number;
  };
  txn: {
    randomSource: number;
    random: ReturnType<typeof getRandomFactory>;
    sigils: SigilMetadata[];
    fromRandomSource: number;
    destinationRandomSource: number;
    lightingRandomSource: number;
  };
  material: {
    randomSource: number;
    random: ReturnType<typeof getRandomFactory>;
  };
  chain: {
    randomSource: number;
  };
  global: {
    randomSource: number;
  };
  wasm: {
    wfc: {
      code: any;
    };
    harrison: {
      code: any;
    };
    postProcess: {
      code: any;
    };
  };
};

export type RandomFn = () => number;

export type Pattern = Uint8Array;
export type Output = Uint8Array;
export type Tile = Uint8Array;

export interface PatternMetadata {
  pattern: Pattern;
  colors: number[];
  width: number;
  height: number;
}

export interface SerializedPatternMetadata
  extends Omit<PatternMetadata, 'pattern'> {
  pattern: number[];
}

export enum Quadrant {
  TopLeft = 0,
  TopRight = 1,
  BottomLeft = 2,
  BottomRight = 3,
}

export type Cord = {
  x: number;
  y: number;
};

export type RectData<T> = {
  topLeft: T;
  bottomRight: T;
};

export type Rect = RectData<Cord>;

export type BoundingRect = Rect;

export type Dimension = {
  w: number;
  h: number;
};

export interface TwoDimensionData<T extends ArrayLike<number> = Uint8Array>
  extends Dimension {
  d: T;
}

export type Range = {
  s: number;
  e: number;
};
export type GenerateArtChainProps = {
  languagePatternMetadata: PatternMetadata;
  symbolsPatternMetadata: PatternMetadata;
  chainRandomSource: number;
};
// specific to runtime and not chain

export type GenerateArtRuntimeProps = {
  wasmArtState: ArtState['wasm'];
  performance?: Performance;
  debug?: boolean;
  resolution: number;
};

export type ArtGene = {
  materialRandomSource: number;
  txnRandomSource: number;
  encodedColorString?: string;
  sigils: ProtoSigilMetadata[];
  fromRandomSource: number;
  destinationRandomSource: number;
  txnTypeRandomSource: number;
  complexityScore: number;
  lightingRandomSource: number;
  degradation: number;
  corpusScore: number;
  corpus: Uint8Array;
};

export interface ArtGeneAdditionalMetadataV1 {
  daysElapsed: number;
  degradationThreshold: number;
  relativeDegradation: number;
  nextDegradationThreshold: number;
  currentRenderingState: {
    blockTimestamp: number;
  };
  regionMarkerWeights: RegionMarkerWeights;
  sigilLogKeys: string[];
  version: '1.0.0';
  colorSource: Evm.Address | null;
}

export interface ArtGeneAdditionalMetadataV2
  extends Omit<ArtGeneAdditionalMetadataV1, 'version'> {
  numEventsPerEventEmitter: {
    [key: Evm.Address]: number;
  };
  emitterLogKeys: Evm.Address[];
  version: '2.0.0';
}

export type ArtGeneAdditionalMetadata =
  | ArtGeneAdditionalMetadataV1
  | ArtGeneAdditionalMetadataV2;

export type GenerateArtProps = GenerateArtChainProps &
  GenerateArtRuntimeProps & {
    gene: ArtGene;
  };

export type HiraethTxnMetadata = {
  transaction: Transaction;
  receipt: TransactionReceipt;
  extraState: TransactionExtraState;
};

export interface HiraethMetadata {
  gene: ArtGene;
  additionalMetadata: ArtGeneAdditionalMetadata;
  txnTypeSigilMetadata: SigilMetadataWithRect;
  fromAddressSigilMetadata: SigilMetadataWithRect;
  destinationSigilMetadata: SigilMetadataWithRect;
  sigilsMetadata: SigilMetadataWithRect[];
  txnMetadata: HiraethTxnMetadata;
  colorPalette: ColorPalette;
}

export enum CompositionSymmetry {
  Quadrant = 0,
  Vertical = 1,
  Horizontal = 2,
}

export enum RegionType {
  Symbol = 0,
  Language = 1,
  Noise = 2,
  SymbolGrid = 4,
  Sigil = 5,
}

export const REVERSE_REGION_TYPE: Record<RegionType, string> = {
  [RegionType.Symbol]: 'Symbol',
  [RegionType.Language]: 'Language',
  [RegionType.Noise]: 'Noise',
  [RegionType.SymbolGrid]: 'Symbol Grid',
  [RegionType.Sigil]: 'Sigil',
};

export type MarkerMetadata = {
  area: number;
  center: Cord;
  boundingRect: BoundingRect;
};

export type ProtoRegionMarkerMetadata = MarkerMetadata & {
  nonSymmetricCenter: Cord;
  ellipticScore: number;
  complexityScore: number; // simple calculation of area over bounding box area
};

export type RegionMarkerMetadata = ProtoRegionMarkerMetadata & {
  normalizedEllipticScore: number;
  normalizedComplexityScore: number;
  normalizedAreaScore: number;
  scriptureScore: number;
  scriptureRank: number;
  continent: number;
  degradation: number;
  regionType: RegionType;
  readDirection: 'vertical' | 'horizontal';
};

export type BorderMarkerMetadata = MarkerMetadata & {
  homogeneity: number; // calculated percentage of how much of the border's area is the same as the continent
  dominantContinent: number;
};

export type RegionMarkerWeights = {
  areaScoreWeight: number;
  ellipticScoreWeight: number;
  complexityScoreWeight: number;
  centerXScoreWeight: number;
  centerYScoreWeight: number;
};

export type SigilMetadataBase = {
  quantity: number;
  type: string;
};

export type ProtoSigilMetadata = SigilMetadataBase &
  (
    | {
        type: 'transfer';
        fromRandomSource: number;
        toRandomSource: number;
        randomSource: number;
      }
    | {
        type: 'default';
        randomSource: number;
      }
    | {
        type: 'address';
        randomSource: number;
      }
    | {
        type: 'txn';
        randomSource: number;
      }
  );

export type SigilMetadata = ProtoSigilMetadata & {
  dimension: Dimension;
};

export type SigilMetadataWithRect = SigilMetadata & {
  rect: Rect | null;
};

import type { Evm } from '@pob/shared';
import {
  AvailableChainId,
  CHAIN_ID_TO_CHAIN_LOOKUP,
  isAvailableChainId,
  type AvailableChain,
} from '@protocol/chains';
import { EvmDeployment, evmDeployments } from '@protocol/deployments';
import React, { ReactNode, useMemo } from 'react';
import { useAccount, usePublicClient } from 'wagmi';
import { UNINITIALIZED_CHAIN } from '~src/constants/rpc';
import { useRuntime } from '~src/hooks/network/useRuntime';
import { useMounted } from '~src/hooks/ui/useMounted';

export interface PreferredNetworkContext {
  chain: AvailableChain;
}

export type PreferredNetworkState = PreferredNetworkContext;

const initialAppState: PreferredNetworkState = {
  chain: UNINITIALIZED_CHAIN as AvailableChain,
};

const PreferredNetworkContext =
  React.createContext<PreferredNetworkState>(initialAppState);

export type PreferredNetworkProviderProps = {
  chainId: AvailableChainId;
  children: ReactNode;
};

const PreferredNetworkProvider: React.FC<PreferredNetworkProviderProps> = ({
  children,
  chainId,
}) => {
  const stateObj = useMemo(() => {
    return {
      // HACK: this is a hack to get the chain to work as available chain
      chain: CHAIN_ID_TO_CHAIN_LOOKUP[chainId] as AvailableChain,
    };
  }, [chainId]);

  return (
    <PreferredNetworkContext.Provider value={stateObj}>
      {children}
    </PreferredNetworkContext.Provider>
  );
};

const usePreferredNetworkContext = (): PreferredNetworkState => {
  return React.useContext(PreferredNetworkContext);
};

const usePreferredChain = <C extends AvailableChain = AvailableChain>(): C => {
  const { chain: preferredChain } = usePreferredNetworkContext();
  // const isMounted = useMounted();
  return useMemo(() => {
    // if (!isMounted) {
    //   return undefined;
    // }
    return preferredChain as C;
    // // if no chain is provided, the wallet is in a unconnected state
    // if (!chain) {
    //   return preferredChain;
    // }
    // if (!!preferredChain && preferredChain.id !== chain.id) {
    //   return undefined;
    // }
    // return chain as AvailableChain | undefined;
  }, [preferredChain]);
};

const usePreferredChainId = <
  C extends AvailableChainId = AvailableChainId,
>() => {
  const chain = usePreferredChain();
  return chain.id as C;
};

const usePreferredRuntime = () => {
  const chain = usePreferredChain();
  return useRuntime(chain);
};

const usePreferredPublicClient = () => {
  const chain = usePreferredChain();
  return usePublicClient({ chainId: chain.id });
};

export type PreferredWalletClientState =
  | 'unmounted'
  | 'unconnected'
  | 'preferred-chain'
  | 'not-preferred-chain';

const usePreferredWalletClientState = (): PreferredWalletClientState => {
  const { chain } = useAccount();
  const { chain: preferredChain } = usePreferredNetworkContext();
  const isMounted = useMounted();
  return useMemo(() => {
    if (!isMounted) {
      return 'unmounted';
    }
    if (!chain) {
      return 'unconnected';
    }
    if (!!preferredChain && preferredChain.id !== chain.id) {
      return 'not-preferred-chain';
    }
    return 'preferred-chain';
  }, [chain, preferredChain, isMounted]);
};

const usePreferredEvmDeployment = () => {
  const chainId = usePreferredChainId();
  return useMemo(() => {
    if (!isAvailableChainId<'evm'>(chainId)) {
      return null;
    }
    if (!evmDeployments[chainId]) {
      return null;
    }
    return evmDeployments[chainId];
  }, [chainId]);
};

const usePreferredEvmAddress = (
  getAddress?: (deployment: EvmDeployment) => Evm.Address | undefined,
) => {
  const chainId = usePreferredChainId();
  return useMemo(() => {
    if (!isAvailableChainId<'evm'>(chainId)) {
      return null;
    }
    if (!getAddress) {
      return null;
    }
    if (!evmDeployments[chainId]) {
      return null;
    }
    const addr = getAddress(evmDeployments[chainId]!);
    if (!addr) {
      return null;
    }
    return addr;
  }, [chainId, getAddress]);
};

export {
  PreferredNetworkProvider,
  usePreferredEvmAddress as usePreferredAddress,
  usePreferredChain,
  usePreferredChainId,
  usePreferredEvmDeployment as usePreferredDeployment,
  usePreferredNetworkContext,
  usePreferredPublicClient,
  usePreferredRuntime,
  usePreferredWalletClientState,
};

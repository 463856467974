import { NULL_ADDRESS } from '@pob/shared';
import { StrippedEvmDeployment } from '../types';
export const baseSepoliaEvmDeployment = {
  pob: {
    multisig: NULL_ADDRESS,
    legacyMultisig: NULL_ADDRESS,
    deployer: '0x09A24e55FFa046AFeA772277FD25e92B6ce6fCf6',
    team: '0x9428ca8b5fe52C33BD0BD7222d719d788B6467F4',
    universalProfile: NULL_ADDRESS,
  },
  account: {
    entryPoint: '0x5ff137d4b0fdcd49dca30c7cf57e578a026d2789',
  },
  libraries: {},
  components: {},
  utils: {
    mintingMultiCall: '0x4a82Dccd69C7Cf1C427dbA62E145937bAfD91f19',
    multiCall: '0xca11bde05977b3631167028862be2a173976ca11',
    scriptyBuilder: NULL_ADDRESS,
    onchainMetadata: '0xCf774c85617Ea916cEB13C14aC5f7EF2134b2730',
    unicode: NULL_ADDRESS,
  },
  scripty: {
    storage: NULL_ADDRESS,
    builder: NULL_ADDRESS,
    ethfsStorage: NULL_ADDRESS,
  },
  projects: {
    dead: undefined,
    hiraeth: {
      nft: '0x4C4C6Da7572fD1B274FE4fcB7552b98554df5917',
      contractMetadata: '0x72cf9c11010f42C2fEea5C957e32F01f4312531f',
      metadata: '0x0b4B75B5cd1Fd695Bd2de228DDc406bF32e3D239',
      logic: '0x4C4C6Da7572fD1B274FE4fcB7552b98554df5917',
      steppedMintingAuthority: '0x498e4359E3DD7ffC8559D2A2d30B17317315F7b9',
    },
    evenOdd: {
      evenMetadata: '0x91bA39fd54cbab185411bd077856C50E012A9887',
      oddMetadata: '0x6e261F0D81Fdb64D0ae42178Aa8e19994F9073b9',
      evenNft: '0xaE37D8a98DbB640a8928C5d2B7fA50d4cA3AE202',
      oddNft: '0x5B2c7ae5AfF057a9547A9B6d4421c6C23098e3E6',
      evenMintingAuthority: '0x6aCbA3207d179266A948a30216552CDB8deaFc76',
      oddMintingAuthority: '0x5937bd142F472fBa471ad4399Bf1906343067f9A',

      perlinNoise: '0x8D41cDe42890fF9497AF269a0599747Ac1794462',
      metadataUtils: '0xf4b9bBf0078F4937916dD6A5Eb0850b1B9e0bB37',
      metadataSvg: '0xA4cD88BEF5a95f02D6792452506CB685D6EB953E',
      metadataWalker: '0x69469E96BA4788e74c9c81335869eeBE79F9eb5A',
      metadataGenerator: '0x46C9d3e7F1a8A1539e17Ef7032a8F072b95c2C36',
      evenWinningMetadata: '0x8224553682EC10E2B65b23E2D7fE3F09b6C3B8DD',
      oddWinningMetadata: '0x9B87F3a915EefF1a57575ab45686F8ce30db9A10',
      evenLosingMetadata: '0x0Eb7B5c1Cdb7aD27a4feaa24d1071E331A7748A0',
      oddLosingMetadata: '0xa50377C121275068aC2505E49F2b3280a20c0fCF',
    },
    exampleWithIpfs: {
      nft: '0xE40632332CEfC52f977b09cc4185C165bae7E9B6',
      contractMetadata: '0xe91A367d1b9CBCd1F56bFA8E5e36FB3b174ce7Aa',
      metadata: '0x40acB541FE2B13D9a461f5b437eafA9770769c16',
    },
    example: undefined,
    exeo: undefined,
    hash: undefined,
    merge: undefined,
    piano: undefined,
    london: undefined,
  },
} as const satisfies StrippedEvmDeployment;

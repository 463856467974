import { NULL_ADDRESS } from '@pob/shared';
import { StrippedEvmDeployment } from '../types';
export const luksoEvmDeployment = {
  pob: {
    multisig: NULL_ADDRESS,
    legacyMultisig: NULL_ADDRESS,
    deployer: '0xd60613c95F6868388AdF8bFA4e84E2c2C9aCCb15',
    team: '0x9428ca8b5fe52C33BD0BD7222d719d788B6467F4',
    universalProfile: '0xF4dB5f2aCFAd310f9C4203a121AF445941704B39',
  },
  account: {
    entryPoint: NULL_ADDRESS,
  },
  libraries: {},
  components: {},
  utils: {
    multiCall: '0xcA11bde05977b3631167028862bE2a173976CA11',
    mintingMultiCall: '0x6e9a9af4a4677131153c4c6f30ab11a01353e189',
    scriptyBuilder: NULL_ADDRESS,
    onchainMetadata: '0xae37d8a98dbb640a8928c5d2b7fa50d4ca3ae202',
    unicode: NULL_ADDRESS,
  },
  scripty: {
    storage: NULL_ADDRESS,
    builder: NULL_ADDRESS,
    ethfsStorage: NULL_ADDRESS,
  },
  projects: {
    dead: undefined,
    hiraeth: {
      // '0x9f2b8810e54821715b313880e5f4d504406cae24'
      nft: '0xB787D41A78d889d28e3D6b3EEA16965D1B9DEEeb',
      contractMetadata: '0xef61d2fae43cd696a2947aa39e9285cb8d72f50f',
      metadata: '0x8f3f6a1dec78d8e3c8afe93c1e3dedb4ce5af17c',
      logic: '0x3ae6d883b1475e2c4598cedaf4ce937b10004bc3',
      steppedMintingAuthority: '0xdea7f35971145aa69d56f6c9dc928aa4db314a88',
      claimMintingAuthority: NULL_ADDRESS,
    },
    exampleWithIpfs: undefined,
    example: undefined,
    evenOdd: undefined,
  exeo: undefined,
    hash: undefined,
    merge: undefined,
    piano: undefined,
    london: undefined,
  },
} as const satisfies StrippedEvmDeployment;

import { keccak256, toHex } from 'viem';
import type { BlockverseContext } from '../types/context';

// export type BlockverseContextKeyGetter<
//   E extends BlockverseProjectId,
//   // AdditiveParams extends any[] = any[],
// > = (
//   type: BlockverseContextType,
//   hash: BlockverseContextHash,
//   ...params: BlockverseObjKeyGetterParams<E>
// ) => BaseBlockverseContextKey<E>;

// type UnknownArrayOrTuple = readonly [...unknown[]];

// type QueryKeyTail<TArray extends UnknownArrayOrTuple> =
//   TArray extends readonly [unknown, unknown, unknown, ...infer Tail]
//     ? Tail
//     : [];

// export const CONTEXT_KEY_GETTERS = {
//   exeo: null,
//   hash: null,
//   hiraeth: (
//     type: BlockverseContextType,
//     hash: BlockverseContextHash,
//     chainId: AvailableChainId,
//   ) => ['hiraeth', chainId, txnHash, type, hash],
// } as const satisfies {
//   [project in BlockverseProjectId]: BlockverseContextKeyGetter<project> | null;
// };

// export type BlockverseProjectContextKeyAdditiveParams<
//   E extends BlockverseProjectId,
// > = (typeof CONTEXT_KEY_GETTERS)[E] extends null
//   ? never
//   : QueryKeyTail<Parameters<NonNullable<(typeof CONTEXT_KEY_GETTERS)[E]>>>;

// export type BlockverseProjectContextKey<E extends BlockverseProjectId> = ReturnType<
//   NonNullable<(typeof CONTEXT_KEY_GETTERS)[E]>
// >;

// export type ExtractObjectKeyFromContextKey<K extends BlockverseContextKey> =
//   K extends [
//     BlockverseProjectId,
//     AvailableChainId,
//     unknown,
//     unknown,
//     ...infer Tail,
//   ]
//     ? [BlockverseProjectId, AvailableChainId, ...Tail]
//     : never;

// export type BlockverseContextObjectKey<E extends BlockverseProjectId> =
//   (typeof CONTEXT_KEY_GETTERS)[E] extends null
//     ? never
//     : ExtractObjectKeyFromContextKey<
//         ReturnType<NonNullable<(typeof CONTEXT_KEY_GETTERS)[E]>>
//       >;

export const getBlockverseContextHash = (context: BlockverseContext) => {
  const { type, temporal, ...hashableContext } = context;
  const jsonStr = JSON.stringify(hashableContext);
  const hash = keccak256(toHex(jsonStr));
  return hash;
};

// export const getObjectKeyFromContextKey = <E extends BlockverseProjectId>(
//   contextKey: BlockverseContextKey,
// ): BlockverseContextObjectKey<E> => {
//   const [projectId, chainId, , , ...restOfQueryKey] = contextKey;
//   return [projectId, chainId, ...restOfQueryKey] as BlockverseContextObjectKey<E>;
// };

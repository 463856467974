import { NULL_ADDRESS } from '@pob/shared';
import { StrippedEvmDeployment } from '../types';
export const luksoTestnetEvmDeployment = {
  pob: {
    multisig: NULL_ADDRESS,
    legacyMultisig: NULL_ADDRESS,
    deployer: '0xd60613c95F6868388AdF8bFA4e84E2c2C9aCCb15',
    team: '0x9428ca8b5fe52C33BD0BD7222d719d788B6467F4',
    universalProfile: '0xF4dB5f2aCFAd310f9C4203a121AF445941704B39',
  },
  account: {
    entryPoint: NULL_ADDRESS,
  },
  libraries: {},
  components: {},
  utils: {
    multiCall: NULL_ADDRESS,
    mintingMultiCall: NULL_ADDRESS,
    scriptyBuilder: NULL_ADDRESS,
    onchainMetadata: NULL_ADDRESS,
    unicode: NULL_ADDRESS,
  },
  scripty: {
    storage: NULL_ADDRESS,
    builder: NULL_ADDRESS,
    ethfsStorage: NULL_ADDRESS,
  },
  projects: {
    dead: undefined,
    hiraeth: {
      nft: '0x6302c8b76308073e31446e32e1c0cf7bd33ffdeb',
      contractMetadata: '0x608e0f0552a7fff75b1504d1591e6eb6354a8fd4',
      metadata: '0xce00039d1374ddbb08289dea7795bfb97b06b264',
      logic: '0xc531c76a7b142dcb35faeb4d0ff243605a7fe8b5',
      steppedMintingAuthority: NULL_ADDRESS,
      claimMintingAuthority: NULL_ADDRESS,
    },
    exampleWithIpfs: undefined,
    example: undefined,
    evenOdd: undefined,
    exeo: undefined,
    hash: undefined,
    merge: undefined,
    piano: undefined,
    london: undefined,
  },
} as const satisfies StrippedEvmDeployment;

import { NULL_ADDRESS } from '@pob/shared';
import type { StrippedEvmDeployment } from '../types';

export const mainnetEvmDeployment = {
  pob: {
    multisig: '0x2ce7D89a9e9DF0DeAe44fdd89fAB6aCbD753d5E4',
    legacyMultisig: '0x28558Ba4343FeB2709ed7A9531B72402a7794D8D',
    deployer: '0xd60613c95F6868388AdF8bFA4e84E2c2C9aCCb15',
    team: '0x9428ca8b5fe52C33BD0BD7222d719d788B6467F4',
    universalProfile: NULL_ADDRESS,
  },
  account: {
    entryPoint: NULL_ADDRESS,
  },
  libraries: {},
  components: {},
  utils: {
    multiCall: '0xcA11bde05977b3631167028862bE2a173976CA11',
    mintingMultiCall: '0x4a82Dccd69C7Cf1C427dbA62E145937bAfD91f19',
    scriptyBuilder: '0x16b727a2Fc9322C724F4Bc562910c99a5edA5084',
    onchainMetadata: '0x4c6989Ae51162449a1AbF9C715eecFF9e544a205',
    unicode: NULL_ADDRESS,
  },
  scripty: {
    storage: '0x096451F43800f207FC32B4FF86F286EdaF736eE3',
    builder: '0x16b727a2Fc9322C724F4Bc562910c99a5edA5084',
    ethfsStorage: '0xFc7453dA7bF4d0c739C1c53da57b3636dAb0e11e',
  },
  projects: {
    dead: {
      token: '0x44ddbeb0ad156869d89eb354b31d96a37e5f4a28',
    },
    hiraeth: {
      nft: '0xa6179Da9C8D3391edcF177397Ed769486B7aAF34',
      contractMetadata: '0x72cf9c11010f42C2fEea5C957e32F01f4312531f',
      metadata: '0xc531c76a7b142dcb35faeb4d0ff243605a7fe8b5',
      logic: '0x694739c8f586b6200a82f8862c6beb1706ea2c8a',
      steppedMintingAuthority: '0xc1E24e2caCD31DcaF08e2345Dc95CF71f00Ae6d3',
      claimMintingAuthority: '0xb88A7a6791192E550434AcE8D76B8eB29e00D1D9',
    },
    exampleWithIpfs: undefined,
    example: undefined,
    evenOdd: undefined,
    exeo: {
      shouldHide: true,
      nft: NULL_ADDRESS,
      metadata: NULL_ADDRESS,
      contractMetadata: NULL_ADDRESS,
      componentUtils: NULL_ADDRESS,
      sampler: '0xCb0b3eF5E491C25770259D6623B5065dfCF58973',
      colorStorage: '0x67Aa7c1aE3A03612087f9Df55fe3F922CDBcF277',
      logic: NULL_ADDRESS,
      resolvers: {
        artBlocks: NULL_ADDRESS,
      },
    },
    hash: {
      repoUrl: 'https://github.com/proofofbeauty/HASH-monorepo',
      nft: '0xf8ff1bf52f4132dcc20ea2442f4c83ed249583bc',
      legacyNft: '0xE18a32192ED95b0FE9D70D19e5025f103475d7BA',
      minter: '0x6133AeeA8BB04Dc9F9B15C9ea799c550c25eb5ED',
      registry: '0x2a8096569F3E922048DF9461d65cF8E675cBF676',
      legacyRegistry: '0x29ca58d4a0857Ae3F0608a546cdE5f254A3F867a',
      deprecatedNft: '0x3c01051673c2A7e7acbbc28aC2bcdfb6a78fFFdC',
    },
    merge: {
      nft: '0x95Cdb0FBf3CcaDABBa38aCC921A9b2381329f727',
      transmutationRitual: '0xa549567E5D2d32c399F487fF8B029D9843451e6F',
      transmutationRitualSimulator:
        '0xbcd81b1f2Cc2BaeA4574CFC248d7c24CE165D3F9',
      mana: '0x1a6B3726A5F0776859E80a668eCf1f43f9Ca1F63',
      repoUrl: 'https://github.com/proofofbeauty/EPICS-monorepo',
    },
    piano: {
      nft: '0x33c4Bfd0F69fa19756aB78Fa3C2dD1331C5d1aCC',
      contractURI:
        'ipfs://bafkreibqkiijyfgmm64tgmmtlt7apa7zyrw6enk52w26t7ihyqk5ofxhua',
      metadataURI: '',
    },
    london: {
      gift: {
        nft: '0x7645eec8bb51862a5aa855c40971b2877dae81af',
        contractURI:
          'ipfs://bafkreifmkpt535hxe4hd2sllvhdgnp5oubtos2bhqmc4sfgizwqssse45q',
        metadataURI:
          'ipfs://bafybeifjgv53l7dj46igfla76rvrvutjupzamdxtj3aeuqqw5fned343ue',
      },
      embers: {
        nft: '0x971fe57134d1b1b3d8d62ccadff1d2cf67e2b8ce',
        contractURI:
          'ipfs://bafkreibutgb6ra6tmbkn7zwg7jmdiatvndmcn74svhtizuqik32qmcpyfe',
        metadataURI: '',
      },
      repoUrl: 'https://github.com/proofofbeauty/LONDON-monorepo',
    },
  },
} as const satisfies StrippedEvmDeployment;

import {
  HARDHAT_CHAIN_ID,
  type AvailableChainIdAndTesting,
} from '@protocol/chains';
import {
  base,
  baseSepolia,
  lukso,
  luksoTestnet,
  mainnet,
  optimism,
  optimismSepolia,
  sepolia,
} from 'viem/chains';
import type { LiteralEvmDeployment, StrippedEvmDeployment } from '../evm/types';

import { baseEvmDeployment } from './chains/base';
import { baseSepoliaEvmDeployment } from './chains/base-sepolia';
import { hardhatEvmDeployment } from './chains/hardhat';
import { luksoEvmDeployment } from './chains/lukso';
import { luksoTestnetEvmDeployment } from './chains/lukso-testnet';
import { mainnetEvmDeployment } from './chains/homestead';
import { optimismEvmDeployment } from './chains/optimism';
import { optimismSepoliaEvmDeployment } from './chains/optimism-sepolia';
import { sepoliaEvmDeployment } from './chains/sepolia';

const strippedEvmDeployments = {
  [mainnet.id]: mainnetEvmDeployment,
  [sepolia.id]: sepoliaEvmDeployment,
  [base.id]: baseEvmDeployment,
  [baseSepolia.id]: baseSepoliaEvmDeployment,
  [optimism.id]: optimismEvmDeployment,
  [optimismSepolia.id]: optimismSepoliaEvmDeployment,
  [lukso.id]: luksoEvmDeployment,
  [luksoTestnet.id]: luksoTestnetEvmDeployment,
  [HARDHAT_CHAIN_ID]: hardhatEvmDeployment,
} as const satisfies Record<
  AvailableChainIdAndTesting<'evm'>,
  StrippedEvmDeployment
>;

export const evmDeployments = strippedEvmDeployments as {
  [K in keyof typeof strippedEvmDeployments]: LiteralEvmDeployment<
    (typeof strippedEvmDeployments)[K]
  >;
};

import { getPatternStyles, patternFns } from '../helpers.mjs';
import { css } from '../css/index.mjs';

const mobileHideConfig = {
transform(props) {
  const { breakPt } = props;
  return {
    display: {
      base: "none",
      [breakPt]: "block"
    }
  };
},
defaultValues:{}}

export const getMobileHideStyle = (styles = {}) => {
  const _styles = getPatternStyles(mobileHideConfig, styles)
  return mobileHideConfig.transform(_styles, patternFns)
}

export const mobileHide = (styles) => css(getMobileHideStyle(styles))
mobileHide.raw = getMobileHideStyle